export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const SET_CATEGORIES = 'SET_CATEGORIES';

export const fetchCategoriesRequest = () => ({
  type: FETCH_CATEGORIES_REQUEST,
});

export const fetchCategoriesSuccess = payload => ({
  payload,
  type: FETCH_CATEGORIES_SUCCESS,
});

export const fetchCategoriesFailure = payload => ({
  payload,
  type: FETCH_CATEGORIES_FAILURE,
});

export const GET_CATEGORY_ICONS_REQUEST = 'GET_CATEGORY_ICONS_REQUEST';
export const GET_CATEGORY_ICONS_SUCCESS = 'GET_CATEGORY_ICONS_SUCCESS';
export const GET_CATEGORY_ICONS_FAILURE = 'GET_CATEGORY_ICONS_FAILURE';

export const getCategoryIconsRequest = () => ({
  type: GET_CATEGORY_ICONS_REQUEST,
});

export const getCategoryIconsSuccess = payload => ({
  payload,
  type: GET_CATEGORY_ICONS_SUCCESS,
});

export const getCategoryIconsFailure = payload => ({
  payload,
  type: GET_CATEGORY_ICONS_FAILURE,
});

export const setCategories = payload => ({
  payload,
  type: SET_CATEGORIES,
});
