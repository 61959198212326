import styled, { css } from 'styled-components';

import { centeredGridColumns, media } from '@utils/styled';

const GridSectionContent = styled.div`
  position: relative;
  grid-column: ${centeredGridColumns(12)};
  max-width: 100%;
  height: 100%;
  ${({ theme, spacingTop }) =>
    spacingTop && theme.getFluidSpacing('margin-top', ...spacingTop)};

  ${({ columns }) => {
    switch (columns) {
      case 10: {
        return css`
          ${media.from1200up`
            grid-column: ${centeredGridColumns(10)};
          `}
        `;
      }
      case 8: {
        return css`
          ${media.from640up`
            grid-column: ${centeredGridColumns(10)};
          `};

          ${media.from1200up`
            grid-column: ${centeredGridColumns(8)};
          `};
        `;
      }
      case 6: {
        return css`
          ${media.from800up`
            grid-column: ${centeredGridColumns(8)};
          `};

          ${media.from1200up`
            grid-column: ${centeredGridColumns(6)};
          `};
        `;
      }
      case 4: {
        return css`
          ${media.from640up`
            grid-column: ${centeredGridColumns(8)};
          `};

          ${media.from800up`
            grid-column: ${centeredGridColumns(6)};
          `};

          ${media.from1200up`
            grid-column: ${centeredGridColumns(4)};
          `};
        `;
      }
      case 2: {
        return css`
          ${media.from480up`
            grid-column: ${centeredGridColumns(8)};
          `};

          ${media.from640up`
            grid-column: ${centeredGridColumns(6)};
          `};

          ${media.from800up`
            grid-column: ${centeredGridColumns(4)};
          `};

          ${media.from1200up`
            grid-column: ${centeredGridColumns(2)};
          `};
        `;
      }
      default: {
        return null;
      }
    }
  }}
`;

export default GridSectionContent;
