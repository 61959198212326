import { toSnakeCase } from 'case-converter';
import Router from 'next/router';
import axios from 'axios';

import { isBrowser } from '@utils/environmentCheck';

import { LANGUAGE_DE } from '@common/constants/languages';

class LocalApiRequest {
  constructor() {
    this.initConfig();
  }

  initConfig() {
    this.instance = axios.create({
      baseURL: process.env.FRONTEND_BASE_URL,
    });

    this.instance.defaults.headers.common['Call-Source'] = isBrowser
      ? 'frontend-browser'
      : 'frontend-node';

    this.instance.interceptors.request.use(async config => {
      return {
        ...config,
        headers: {
          ...config.headers.common,
          'Accept-Language':
            isBrowser && Router?.locale ? Router.locale : LANGUAGE_DE,
        },
        data: config.data ? toSnakeCase(config.data) : config.data,
        params: config.params ? toSnakeCase(config.params) : config.params,
      };
    });
  }

  get(...args) {
    return this.instance.get(...args);
  }

  post(...args) {
    return this.instance.post(...args);
  }

  options(...args) {
    return this.instance.options(...args);
  }

  patch(...args) {
    return this.instance.patch(...args);
  }

  put(...args) {
    return this.instance.put(...args);
  }

  delete(...args) {
    return this.instance.delete(...args);
  }
}

export default new LocalApiRequest();
