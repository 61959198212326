import flatMapDeep from 'lodash/flatMapDeep';
import keyBy from 'lodash/keyBy';

import * as actions from '@actions/categories';

const initialState = {
  data: [],
  flattenData: {},
  icons: {},
  isFetchingCategories: false,
  isFetchingCategoryIcons: false,
};

export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        isFetchingCategories: true,
      };

    case actions.FETCH_CATEGORIES_SUCCESS: {
      if (!Array.isArray(action.payload)) {
        return {
          ...state,
          isFetchingCategories: false,
        };
      }

      const flattenData = keyBy(
        flatMapDeep(action.payload, ({ children, ...cat }) =>
          children && children.length ? [cat, children] : cat,
        ),
        'categoryId',
      );

      return {
        ...state,
        data: action.payload,
        flattenData,
        isFetchingCategories: false,
      };
    }

    case actions.FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        isFetchingCategories: false,
      };

    case actions.GET_CATEGORY_ICONS_REQUEST:
      return {
        ...state,
        isFetchingCategoryIcons: true,
      };

    case actions.GET_CATEGORY_ICONS_SUCCESS: {
      const icons = action.payload.reduce((acc, { categoryId, svg }) => {
        acc[categoryId] = svg;

        return acc;
      }, {});

      return {
        ...state,
        icons,
        isFetchingCategoryIcons: false,
      };
    }

    case actions.GET_CATEGORY_ICONS_FAILURE:
      return {
        ...state,
        isFetchingCategoryIcons: false,
      };

    case actions.SET_CATEGORIES:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
