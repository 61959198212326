export const GET_MENU_REQUEST = 'GET_MENU_REQUEST';
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const GET_MENU_FAILURE = 'GET_MENU_FAILURE';
export const SET_MENUS = 'SET_MENUS';

export const getMenuRequest = identifier => ({
  identifier,
  type: GET_MENU_REQUEST,
});

export const getMenuSuccess = (identifier, payload) => ({
  identifier,
  payload,
  type: GET_MENU_SUCCESS,
});

export const getMenuFailure = (identifier, error) => ({
  error,
  identifier,
  type: GET_MENU_FAILURE,
});

export const setMenus = payload => ({
  payload,
  type: SET_MENUS,
});
