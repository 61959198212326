import { useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';

import { isBrowser } from '@utils/environmentCheck';

const THRESHOLD = 60;

const useStickyBarVisibility = () => {
  const [isStickyBarVisible, setStickyTitleVisible] = useState(false);
  const stickyVisibleRef = useRef();

  stickyVisibleRef.current = isStickyBarVisible;

  useEffect(() => {
    if (!isBrowser) {
      return undefined;
    }

    const handleScrollEvent = throttle(() => {
      const { scrollY } = window;

      if (!stickyVisibleRef.current && scrollY >= THRESHOLD) {
        setStickyTitleVisible(true);

        return;
      }

      if (stickyVisibleRef.current && scrollY < THRESHOLD) {
        setStickyTitleVisible(false);
      }
    }, 100);

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  return isStickyBarVisible;
};

export default useStickyBarVisibility;
