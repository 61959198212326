import PropTypes from 'prop-types';
import React from 'react';

export const YoutubeIcon = ({ alt, accessibilityId, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="28"
    height="20"
    viewBox="0 0 28 20"
    aria-labelledby={accessibilityId}
    {...props}
  >
    <title id={accessibilityId}>{alt}</title>
    <path d="M11.08 13.94v-8l7.44 3.92-7.44 4.08zm16.64-9.63s-.27-1.96-1.11-2.82C25.54.36 24.35.35 23.8.3 19.87 0 14 0 14 0h-.02S8.12 0 4.2.29c-.54.06-1.73.07-2.8 1.2C.55 2.35.28 4.31.28 4.31S0 6.61 0 8.91v2.16c0 2.3.28 4.6.28 4.6s.27 1.97 1.11 2.83c1.07 1.13 2.47 1.1 3.09 1.21C6.72 19.93 14 20 14 20s5.88 0 9.8-.3c.55-.06 1.74-.07 2.8-1.2.85-.86 1.12-2.82 1.12-2.82s.28-2.3.28-4.6V8.91c0-2.3-.28-4.6-.28-4.6z" />
  </svg>
);

YoutubeIcon.propTypes = {
  alt: PropTypes.string.isRequired,
  accessibilityId: PropTypes.string.isRequired,
};
