import React from 'react';
import PropTypes from 'prop-types';

export function SvgComponent({ fill, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      height={60}
      viewBox="0 0 42 60"
      {...rest}
    >
      <path
        d="M28.5,48 L33,48 L33,21 L28.5,21 L28.5,48 Z M16.5,12 L25.5,12 L25.5,4.5 L16.5,4.5 L16.5,12 Z M18.75,48 L23.25,48 L23.25,21 L18.75,21 L18.75,48 Z M9,48 L13.5,48 L13.5,21 L9,21 L9,48 Z M36,12 L30,12 L30,3 C30,1.35 28.65,0 27,0 L15,0 C13.35,0 12,1.35 12,3 L12,12 L6,12 C2.7,12 0,14.7 0,18 L0,51 C0,54.3 2.7,57 6,57 C6,58.65 7.35,60 9,60 C10.65,60 12,58.65 12,57 L30,57 C30,58.65 31.35,60 33,60 C34.65,60 36,58.65 36,57 C39.3,57 42,54.3 42,51 L42,18 C42,14.7 39.3,12 36,12 L36,12 Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

SvgComponent.propTypes = {
  fill: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: '#a8acb3',
};

export default SvgComponent;
