import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { selectIsUserLogged } from '@selectors/auth';
import { unfavorite } from '@thunks/favorites';
import { unfavoriteUnauthenticated } from '@actions/favorites';

const defaultMultiDeleteValue = {
  isMultiDeleteEnabled: false,
  selectedProducts: [],
};

export const FavoritesMultiDeleteContext = createContext(
  defaultMultiDeleteValue,
);
export const useFavoritesMultiDelete = () =>
  useContext(FavoritesMultiDeleteContext);

export const FavoritesMultiDeleteProvider = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const isUserLogged = useSelector(selectIsUserLogged);

  const onSelectProduct = useCallback(
    productId => {
      if (selectedProducts.includes(productId)) {
        setSelectedProducts(selectedProducts.filter(id => id !== productId));
      } else {
        setSelectedProducts([...selectedProducts, productId]);
      }
    },
    [selectedProducts],
  );

  const onClearSelectedProducts = useCallback(() => {
    setSelectedProducts([]);
  }, []);

  const onToggleMultiDelete = useCallback(() => {
    if (isEnabled) {
      onClearSelectedProducts();
    }
    setIsEnabled(prev => !prev);
  }, [isEnabled, onClearSelectedProducts]);

  const onConfirmMultiDelete = useCallback(() => {
    if (isUserLogged) {
      dispatch(unfavorite(selectedProducts));
    } else {
      dispatch(unfavoriteUnauthenticated(selectedProducts));
    }

    onToggleMultiDelete();
  }, [dispatch, isUserLogged, onToggleMultiDelete, selectedProducts]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isEnabled) {
        setIsEnabled(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [isEnabled, router]);

  return (
    <FavoritesMultiDeleteContext.Provider
      value={{
        isMultiDeleteEnabled: isEnabled,
        selectedProducts,
        setSelectedProducts,
        onSelectProduct,
        onClearSelectedProducts,
        onToggleMultiDelete,
        onConfirmMultiDelete,
        close: () => setIsEnabled(false),
        isProductSelected: productId => selectedProducts.includes(productId),
      }}
    >
      {children}
    </FavoritesMultiDeleteContext.Provider>
  );
};

FavoritesMultiDeleteProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
