import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 20"
      fill="#fff"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M4 20H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h3c.552 0 1 .448 1 1v18c0 .552-.448 1-1 1m10 0h-3c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h3c.552 0 1 .448 1 1v18c0 .552-.448 1-1 1"
      />
    </svg>
  );
}

export default SvgComponent;
