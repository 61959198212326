const { LANGUAGE_FR } = require('./languages');

exports.GIFT_CARDS_VIEW_MODE_DE = {
  PURCHASE: 'bestellen',
  BALANCE: 'saldo',
};

exports.GIFT_CARDS_VIEW_MODES_DE = Object.values(
  exports.GIFT_CARDS_VIEW_MODE_DE,
);

exports.GIFT_CARDS_VIEW_MODE_FR = {
  PURCHASE: 'commander',
  BALANCE: 'solde',
};

exports.GIFT_CARDS_VIEW_MODES_FR = Object.values(
  exports.GIFT_CARDS_VIEW_MODE_FR,
);

exports.GIFT_CARDS_VIEW_MODES = new Proxy(
  {},
  {
    get(obj, language) {
      return language === LANGUAGE_FR
        ? exports.GIFT_CARDS_VIEW_MODES_FR
        : exports.GIFT_CARDS_VIEW_MODES_DE;
    },
  },
);

exports.GIFT_CARDS_VIEW_MODE = new Proxy(
  {},
  {
    get(obj, language) {
      return language === LANGUAGE_FR
        ? exports.GIFT_CARDS_VIEW_MODE_FR
        : exports.GIFT_CARDS_VIEW_MODE_DE;
    },
  },
);

exports.DELIVERY_OPTIONS = {
  TO_MY_HOME: false,
  TO_THE_RECIPIENT: true,
};
