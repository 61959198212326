import React from 'react';
import PropTypes from 'prop-types';

export const PriceText = ({ children }) => {
  if (!children) return [];

  const parts = children.split(/<sup>([\w]+)<\/sup>/gi);

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = <sup key={i}>{parts[i]}</sup>;
  }

  return parts;
};

PriceText.propTypes = {
  children: PropTypes.string.isRequired,
};

export default PriceText;
