import React from 'react';
import PropTypes from 'prop-types';

import PriceText from '@common/components/PriceText';
import useStickyBarVisibility from '@common/hooks/useStickyBarVisibility';
import { useUserAgent } from '@common/contexts/userAgentContext';
import LayoutOverlay from '@common/containers/LayoutOverlay/LayoutOverlay';
import { useScroll } from '@common/hooks/useScroll';
import GridSectionContent from '@common/components/GridSectionContent';

import {
  Wrapper,
  AnimatedBar,
  StickyTitle,
  StickyControls,
  BackButton,
} from './StickyBar.styled';

const BackButtonComponent = ({
  isAlertMessagesVisible,
  isHeroAlwaysExpanded,
  isMobileHero,
  isHero,
  isProduct,
  handleBackButtonClick,
  notAnimated,
  backRoute,
  backRouteAs,
  isStickyTitleVisible,
  alwaysVisible,
  alertHeight,
}) => (
  <BackButton
    href={backRoute}
    forwardedAs={backRouteAs}
    isVisible={isStickyTitleVisible || alwaysVisible}
    onClick={handleBackButtonClick}
    isProduct={isProduct}
    isHero={isHero}
    isMobileHero={isHero || isMobileHero}
    isHeroAlwaysExpanded={isHeroAlwaysExpanded}
    isAlertMessagesVisible={isAlertMessagesVisible}
    notAnimated={notAnimated}
    alertHeight={alertHeight}
  />
);

const StickyBar = ({
  children,
  backRoute,
  backRouteAs,
  handleBackButtonClick,
  columns,
  stickyTitle,
  additionalControls,
  isProduct,
  isHero,
  isMobileHero,
  alwaysVisible,
  isTransparent,
  expandedStickyColumns,
  isHeroAlwaysExpanded,
  isAlertMessagesVisible,
  alertHeight,
  stickyTitleEllipsisPadding,
  forceOnMobileApp,
}) => {
  const { isMobileApp } = useUserAgent();
  const isStickyTitleVisible = useStickyBarVisibility();

  const { scrollDirection } = useScroll();

  const isScrollingDown = scrollDirection === 'down';

  return (
    <Wrapper
      isHero={isHero}
      isMobileHero={isMobileHero}
      isProduct={isProduct}
      isMobileApp={isMobileApp}
      isTransparent={isTransparent}
      isScrollingDown={isScrollingDown}
      isAlertMessagesVisible={isAlertMessagesVisible}
      alertHeight={alertHeight}
    >
      <LayoutOverlay disableContent />
      {children}
      <GridSectionContent
        columns={
          isStickyTitleVisible && expandedStickyColumns
            ? expandedStickyColumns
            : columns
        }
      >
        <React.Fragment>
          {(backRoute || handleBackButtonClick) && !isMobileApp && (
            <BackButtonComponent
              notAnimated
              isAlertMessagesVisible={isAlertMessagesVisible}
              isHeroAlwaysExpanded={isHeroAlwaysExpanded}
              isMobileHero={isMobileHero}
              isHero={isHero}
              isProduct={isProduct}
              handleBackButtonClick={handleBackButtonClick}
              backRoute={backRoute}
              backRouteAs={backRouteAs}
              isStickyTitleVisible={isStickyTitleVisible}
              alwaysVisible={alwaysVisible}
              alertHeight={alertHeight}
            />
          )}
          {(!isMobileApp || forceOnMobileApp) && (
            <AnimatedBar
              isVisible={isStickyTitleVisible || alwaysVisible}
              isMobileApp={isMobileApp}
              isAlertMessagesVisible={isAlertMessagesVisible}
              isScrollingDown={isScrollingDown}
              alertHeight={alertHeight}
            >
              {(backRoute || handleBackButtonClick) && !isMobileApp && (
                <BackButtonComponent
                  isAlertMessagesVisible={isAlertMessagesVisible}
                  isHeroAlwaysExpanded={isHeroAlwaysExpanded}
                  isMobileHero={isMobileHero}
                  isHero={isHero}
                  isProduct={isProduct}
                  handleBackButtonClick={handleBackButtonClick}
                  backRoute={backRoute}
                  backRouteAs={backRouteAs}
                  isStickyTitleVisible={isStickyTitleVisible}
                  alwaysVisible={alwaysVisible}
                  alertHeight={alertHeight}
                />
              )}
              {stickyTitle && (
                <StickyTitle
                  stickyTitleEllipsisPadding={stickyTitleEllipsisPadding}
                  withControls={!!additionalControls}
                >
                  <PriceText>{stickyTitle}</PriceText>
                </StickyTitle>
              )}
            </AnimatedBar>
          )}
        </React.Fragment>
        {(backRoute || handleBackButtonClick) && additionalControls && (
          <StickyControls>{additionalControls}</StickyControls>
        )}
      </GridSectionContent>
    </Wrapper>
  );
};

BackButtonComponent.propTypes = {
  notAnimated: PropTypes.bool,
  isAlertMessagesVisible: PropTypes.bool.isRequired,
  isHeroAlwaysExpanded: PropTypes.bool.isRequired,
  isMobileHero: PropTypes.bool.isRequired,
  isHero: PropTypes.bool.isRequired,
  isProduct: PropTypes.bool.isRequired,
  handleBackButtonClick: PropTypes.func.isRequired,
  backRoute: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]).isRequired,
  isStickyTitleVisible: PropTypes.bool.isRequired,
  alwaysVisible: PropTypes.bool.isRequired,
  backRouteAs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]).isRequired,
  alertHeight: PropTypes.number.isRequired,
};

BackButtonComponent.defaultProps = {
  notAnimated: false,
};

StickyBar.defaultProps = {
  children: null,
  additionalControls: null,
  backRoute: undefined,
  backRouteAs: undefined,
  stickyTitle: undefined,
  handleBackButtonClick: undefined,
  isHero: false,
  isMobileHero: false,
  isProduct: false,
  alwaysVisible: false,
  isTransparent: false,
  expandedStickyColumns: undefined,
  isHeroAlwaysExpanded: false,
  isAlertMessagesVisible: false,
  forceOnMobileApp: false,
  stickyTitleEllipsisPadding: '5rem',
};

StickyBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  additionalControls: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  backRoute: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]),
  backRouteAs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]),
  columns: PropTypes.oneOf([12, 10, 8, 6, 4, 2]).isRequired,
  stickyTitle: PropTypes.string,
  handleBackButtonClick: PropTypes.func,
  isHero: PropTypes.bool,
  isMobileHero: PropTypes.bool,
  isProduct: PropTypes.bool,
  alwaysVisible: PropTypes.bool,
  isTransparent: PropTypes.bool,
  expandedStickyColumns: PropTypes.number,
  isHeroAlwaysExpanded: PropTypes.bool,
  isAlertMessagesVisible: PropTypes.bool,
  forceOnMobileApp: PropTypes.bool,
  alertHeight: PropTypes.number.isRequired,
  stickyTitleEllipsisPadding: PropTypes.string,
};

export default StickyBar;
