import {
  COMPANY_MENU_IDENTIFIER,
  FOR_CLIENTS_MENU_IDENTIFIER,
  LEGAL_MENU_IDENTIFIER,
  MAIN_MENU_IDENTIFIER,
} from '@common/constants/menus';
import * as actions from '../actions/menus';

const initialState = {
  [COMPANY_MENU_IDENTIFIER]: null,
  [FOR_CLIENTS_MENU_IDENTIFIER]: null,
  [LEGAL_MENU_IDENTIFIER]: null,
  [MAIN_MENU_IDENTIFIER]: null,
};

export default function menusReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_MENU_SUCCESS:
      return { ...state, [action.identifier]: action.payload };

    case actions.SET_MENUS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
