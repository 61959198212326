import { createContext, useContext } from 'react';

import AttributesSet from './AttributesSet';

export const BodyAttributesContext = createContext();

const masterBodyAttributes = new AttributesSet();

export const useBodyAttributes = () =>
  useContext(BodyAttributesContext) || masterBodyAttributes;
