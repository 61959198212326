import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={11} height={9} viewBox="0 0 11 9" {...props}>
      <path d="M10.1 0L3.5 7.1.9 4.3l-.9.9L3.5 9 11 .9z" />
    </svg>
  );
}

export default SvgComponent;
