export const PROFILE_PICTURE_REQUEST = 'PROFILE_PICTURE_REQUEST';
export const PROFILE_PICTURE_SUCCESS = 'PROFILE_PICTURE_SUCCESS';
export const PROFILE_PICTURE_FAILURE = 'PROFILE_PICTURE_FAILURE';

export const profilePictureRequest = () => ({
  type: PROFILE_PICTURE_REQUEST,
});

export const profilePictureSuccess = payload => ({
  payload,
  type: PROFILE_PICTURE_SUCCESS,
});

export const profilePictureFailure = () => ({
  type: PROFILE_PICTURE_FAILURE,
});

export const REMOVE_PROFILE_PICTURE_REQUEST = 'REMOVE_PROFILE_PICTURE_REQUEST';
export const REMOVE_PROFILE_PICTURE_SUCCESS = 'REMOVE_PROFILE_PICTURE_SUCCESS';
export const REMOVE_PROFILE_PICTURE_FAILURE = 'REMOVE_PROFILE_PICTURE_FAILURE';

export const removeProfilePictureRequest = () => ({
  type: REMOVE_PROFILE_PICTURE_REQUEST,
});

export const removeProfilePictureSuccess = () => ({
  type: REMOVE_PROFILE_PICTURE_SUCCESS,
});

export const removeProfilePictureFailure = () => ({
  type: REMOVE_PROFILE_PICTURE_FAILURE,
});

export const UPLOAD_PROFILE_PICTURE_REQUEST = 'UPLOAD_PROFILE_PICTURE_REQUEST';
export const UPLOAD_PROFILE_PICTURE_SUCCESS = 'UPLOAD_PROFILE_PICTURE_SUCCESS';
export const UPLOAD_PROFILE_PICTURE_FAILURE = 'UPLOAD_PROFILE_PICTURE_FAILURE';

export const uploadProfilePictureRequest = () => ({
  type: UPLOAD_PROFILE_PICTURE_REQUEST,
});

export const uploadProfilePictureSuccess = payload => ({
  payload,
  type: UPLOAD_PROFILE_PICTURE_SUCCESS,
});

export const uploadProfilePictureFailure = () => ({
  type: UPLOAD_PROFILE_PICTURE_FAILURE,
});

export const CLEAR_PROFILE_PICTURE = 'CLEAR_PROFILE_PICTURE';

export const clearProfilePicture = () => ({
  type: CLEAR_PROFILE_PICTURE,
});
