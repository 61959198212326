import { createContext, useContext } from 'react';

const StickyBarVisibilityContext = createContext(false);

StickyBarVisibilityContext.displayName = 'StickyBarVisibilityContext';

export const StickyBarVisibilityProvider = StickyBarVisibilityContext.Provider;

export const useStickyBarVisibilityContext = () =>
  useContext(StickyBarVisibilityContext);
