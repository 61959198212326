import { memo, useEffect } from 'react';

import { useBodyAttributes } from './bodyAttributesContext';

const BodyAttributes = memo(props => {
  const { setBodyAttribute, removeBodyAttribute } = useBodyAttributes();

  Object.keys(props).forEach(attr => setBodyAttribute(attr, props[attr]));

  useEffect(
    () => () => {
      Object.keys(props).forEach(attr => removeBodyAttribute(attr));
    },
    [],
  );

  return null;
});

export default BodyAttributes;
