const PREFIX = '[FILTERS]';

export const FETCH_FILTERS_OPTIONS_REQUEST = `${PREFIX} FETCH_FILTERS_OPTIONS_REQUEST`;
export const FETCH_FILTERS_OPTIONS_SUCCESS = `${PREFIX} FETCH_FILTERS_OPTIONS_SUCCESS`;
export const FETCH_FILTERS_OPTIONS_FAILURE = `${PREFIX} FETCH_FILTERS_OPTIONS_FAILURE`;

export const fetchFiltersOptionsRequest = category => ({
  category,
  type: FETCH_FILTERS_OPTIONS_REQUEST,
});

export const fetchFiltersOptionsSuccess = (category, payload) => ({
  category,
  payload,
  type: FETCH_FILTERS_OPTIONS_SUCCESS,
});

export const fetchFiltersOptionsFailure = (category, payload) => ({
  category,
  payload,
  type: FETCH_FILTERS_OPTIONS_FAILURE,
});

export const SET_INITIAL_FILTER_OPTIONS = `${PREFIX} SET_INITIAL_FILTER_OPTIONS`;

export const setInitialFilterOptions = (category, payload = null) => ({
  category,
  payload,
  type: SET_INITIAL_FILTER_OPTIONS,
});

export const SET_FILTER_SIZES = `${PREFIX} SET_FILTER_SIZES`;
export const SET_FILTER_COLORS = `${PREFIX} SET_FILTER_COLORS`;

export const setFilterSizes = (payload = null) => ({
  payload,
  type: SET_FILTER_SIZES,
});

export const setFilterColors = (payload = null) => ({
  payload,
  type: SET_FILTER_COLORS,
});
