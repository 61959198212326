import * as actions from '@actions/favorites';
import { selectGetUID } from '@selectors/auth';

import request from '@utils/request';

import * as endpoints from '@common/endpoints';

export const fetchFavorites = () => (dispatch, getState) => {
  const customerId = selectGetUID(getState());

  dispatch(actions.getFavoritesRequest());

  return request
    .get(endpoints.favorites(customerId))
    .then(({ data }) => {
      dispatch(actions.getFavoritesSuccess(data));
    })
    .catch(error => {
      dispatch(actions.getFavoritesFailure(error));
    });
};

export const favorite = ids => (dispatch, getState) => {
  const customerId = selectGetUID(getState());
  const productColorIds = Array.isArray(ids) ? ids : [ids];

  dispatch(actions.addFavoriteRequest(productColorIds));

  return request
    .patch(endpoints.favoritesBulkAdd(customerId), {
      productColorIds,
    })
    .then(({ data }) => {
      dispatch(actions.addFavoriteSuccess(data));
    })
    .catch(error => {
      dispatch(actions.addFavoriteFailure(error));
    });
};

export const unfavorite = ids => (dispatch, getState) => {
  const customerId = selectGetUID(getState());
  const productColorIds = Array.isArray(ids) ? ids : [ids];

  dispatch(actions.unfavoriteRequest(productColorIds));

  return request
    .patch(endpoints.favoritesBulkDelete(customerId), {
      productColorIds,
    })
    .then(() => {
      dispatch(actions.unfavoriteSuccess(productColorIds));
    })
    .catch(error => {
      dispatch(actions.unfavoriteFailure(error));
    });
};
