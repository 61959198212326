export const LAYOUT_GRID = 0;
export const LAYOUT_COLUMN = 1;
export const LAYOUTS = [LAYOUT_GRID, LAYOUT_COLUMN];

export const STICKY_HEADER_HEIGHT = 6.1;
export const NEGATIVE_STICKY_HEADER_HEIGHT = -STICKY_HEADER_HEIGHT;
export const MOBILE_ALPHABET_PICKER_HEIGHT = 5 * 10;

export const NAVBAR_HEIGHT = 6;
export const NAVBAR_HEIGHT_PX = 6 * 10;
