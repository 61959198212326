import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" fill="#000000" {...props}>
      <path d="M12,0 L12,10.454 L22,10.4545455 L22,12.5454545 L12,12.545 L12,23 L10,23 L10,12.545 L0,12.5454545 L0,10.4545455 L10,10.454 L10,0 L12,0 Z" />
    </svg>
  );
}

export default SvgComponent;
