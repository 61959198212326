import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '../actions/barcode';

const initialState = {
  imageUrl: '',
  isFetching: false,
};

export default function barcodeReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...action.payload.barcode,
      };

    case actions.GET_BARCODE_REQUEST:
      return { ...state, isFetching: true };

    case actions.GET_BARCODE_SUCCESS:
      return {
        ...state,
        imageUrl: action.payload.mainBarcodeUrl,
        isFetching: false,
      };

    case actions.GET_BARCODE_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
}
