import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

const GlobalOverlayContext = React.createContext({
  layoutOverlay: {},
  setLayoutOverlay: () => {},
});

export const GlobalOverlayContextProvider = ({ children }) => {
  const router = useRouter();
  const [layoutOverlay, setLayoutOverlay] = useState({
    innerContent: null,
    isActive: false,
  });

  useEffect(() => {
    const handleRouteChange = () => {
      if (layoutOverlay.isActive) {
        setLayoutOverlay({
          innerContent: null,
          isScrollDisabled: false,
          isActive: false,
        });
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [layoutOverlay.isActive, router]);

  return (
    <GlobalOverlayContext.Provider
      value={{
        layoutOverlay,
        setLayoutOverlay: params =>
          setLayoutOverlay(prev => ({ ...prev, ...params })),
      }}
    >
      {children}
    </GlobalOverlayContext.Provider>
  );
};

GlobalOverlayContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useGlobalOverlayContext = () => useContext(GlobalOverlayContext);
