import PropTypes from 'prop-types';
import React from 'react';

export const InstagramIcon = ({ alt, accessibilityId, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    aria-labelledby={accessibilityId}
    {...props}
  >
    <title id={accessibilityId}>{alt}</title>
    <path d="M14.73 0c2.57 0 3.08.02 4.34.08 1.43.07 2.41.3 3.27.63.9.34 1.64.8 2.4 1.56.75.75 1.2 1.5 1.55 2.39.34.86.56 1.84.63 3.27.06 1.44.08 1.9.08 5.57 0 3.67-.02 4.13-.08 5.57a9.95 9.95 0 01-.63 3.27c-.34.9-.8 1.64-1.56 2.4a6.62 6.62 0 01-2.39 1.55c-.86.34-1.84.56-3.27.63-1.44.06-1.9.08-5.57.08-3.67 0-4.13-.02-5.57-.08a9.92 9.92 0 01-3.27-.63 6.62 6.62 0 01-2.4-1.56 6.62 6.62 0 01-1.55-2.39 9.95 9.95 0 01-.63-3.27A77.14 77.14 0 010 14.73v-2.46C0 9.7.02 9.19.08 7.94c.07-1.44.3-2.42.63-3.28.34-.89.8-1.64 1.56-2.4A6.6 6.6 0 014.66.72 9.91 9.91 0 017.93.08C9.2.02 9.7 0 12.27 0zm-.02 2.43H12.3c-2.52 0-3 .02-4.24.08a7.42 7.42 0 00-2.51.47c-.63.24-1.08.53-1.55 1-.48.48-.77.93-1.01 1.56-.19.47-.4 1.19-.47 2.5a93.94 93.94 0 00-.08 5.46c0 3.6.02 4.03.08 5.46.06 1.31.28 2.03.47 2.5.24.63.53 1.08 1 1.55.48.48.93.77 1.56 1.01.47.19 1.19.41 2.5.47 1.43.07 1.86.08 5.46.08 3.6 0 4.03-.02 5.45-.08a7.44 7.44 0 002.51-.47 4.18 4.18 0 001.55-1c.48-.48.77-.93 1.01-1.56.19-.47.4-1.19.47-2.5.06-1.43.08-1.86.08-5.46 0-3.6-.02-4.03-.08-5.46a7.47 7.47 0 00-.47-2.5 4.18 4.18 0 00-1-1.55 4.17 4.17 0 00-1.56-1.01c-.47-.19-1.19-.4-2.5-.47a73.35 73.35 0 00-4.25-.08zM13.5 6.57a6.93 6.93 0 110 13.86 6.93 6.93 0 010-13.86zm0 2.43a4.5 4.5 0 100 9 4.5 4.5 0 000-9zm7.2-4.33a1.62 1.62 0 110 3.24 1.62 1.62 0 010-3.24z" />
  </svg>
);

InstagramIcon.propTypes = {
  alt: PropTypes.string.isRequired,
  accessibilityId: PropTypes.string.isRequired,
};
