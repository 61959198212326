import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="#202226" {...props}>
      <path d="M3.6,4.8 C2.936,4.8 2.4,4.264 2.4,3.6 C2.4,2.936 2.936,2.4 3.6,2.4 C4.264,2.4 4.8,2.936 4.8,3.6 C4.8,4.264 4.264,4.8 3.6,4.8 M15.528,7.528 L8.464,0.464 C8.168,0.168 7.76,0 7.336,0 L1.6,0 C0.72,0 0,0.72 0,1.6 L0,7.336 C0,7.76 0.168,8.168 0.472,8.464 L7.536,15.528 C8.16,16.152 9.176,16.152 9.8,15.528 L15.536,9.792 C16.16,9.168 16.16,8.16 15.528,7.528" />
    </svg>
  );
}

export default SvgComponent;
