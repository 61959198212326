import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 20"
      fill="#fff"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M15.5081031 10.9313322 1.50439082 19.8492857C.83954392 20.2729209 0 19.7531032 0 18.9177205V1.0818136C0 .2464309.83954392-.2733868 1.50439082.15132911L15.5081031 9.06820188c.6558625.4182317.6558625 1.44489862 0 1.86313032"
      />
    </svg>
  );
}

export default SvgComponent;
