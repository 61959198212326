import { HYDRATE } from 'next-redux-wrapper';

import {
  GET_DEFAULT_META_INFO_REQUEST,
  GET_DEFAULT_META_INFO_SUCCESS,
  GET_DEFAULT_META_INFO_FAILURE,
} from '../actions/defaultMetaInfo';

const initialState = {
  data: {
    defaultDescription: '',
    defaultImage: {
      url: '',
      title: '',
      altText: '',
    },
  },
  isFetching: false,
};

export default function defaultMetaInfoReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...action.payload.defaultMetaInfo,
      };

    case GET_DEFAULT_META_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case GET_DEFAULT_META_INFO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      };

    case GET_DEFAULT_META_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
