import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={23} height={26} viewBox="0 0 23 26" {...props}>
      <path d="M11.2 13.7c3.7 0 11.1 2 11.2 5.7-2.4 3.6-6.5 6-11.2 6S2.4 23 0 19.4c.1-3.7 7.5-5.7 11.2-5.7zm0-13.7c3.1 0 5.6 2.5 5.6 5.6s-2.5 5.6-5.6 5.6-5.6-2.5-5.6-5.6S8.1 0 11.2 0z" />
    </svg>
  );
}

export default SvgComponent;
