import { HYDRATE } from 'next-redux-wrapper';

import {
  GET_GIFT_CARDS_DISCOUNT_REQUEST,
  GET_GIFT_CARDS_DISCOUNT_SUCCESS,
  GET_GIFT_CARDS_DISCOUNT_FAILURE,
} from '@actions/giftCards';

const initialState = {
  data: {},
  isFetching: false,
};

export default function giftCardsDiscountReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case HYDRATE:
      return {
        ...payload.giftCards.discount,
      };

    case GET_GIFT_CARDS_DISCOUNT_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case GET_GIFT_CARDS_DISCOUNT_SUCCESS: {
      return {
        ...state,
        data: {
          ...payload,
        },
        isFetching: false,
      };
    }

    case GET_GIFT_CARDS_DISCOUNT_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }

    default:
      return state;
  }
}
