export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const getProductsRequest = ids => ({
  ids,
  type: GET_PRODUCTS_REQUEST,
});

export const getProductsSuccess = payload => ({
  payload,
  type: GET_PRODUCTS_SUCCESS,
});

export const getProductsFailure = payload => ({
  payload,
  type: GET_PRODUCTS_FAILURE,
});

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

export const getProductRequest = id => ({
  id,
  type: GET_PRODUCT_REQUEST,
});

export const getProductSuccess = (id, payload) => ({
  id,
  payload,
  type: GET_PRODUCT_SUCCESS,
});

export const getProductFailure = (id, payload) => ({
  id,
  payload,
  type: GET_PRODUCT_FAILURE,
});

export const GET_AVAILABLE_PRODUCTS_COUNT_REQUEST =
  'GET_AVAILABLE_PRODUCTS_COUNT_REQUEST';
export const GET_AVAILABLE_PRODUCTS_COUNT_SUCCESS =
  'GET_AVAILABLE_PRODUCTS_COUNT_SUCCESS';
export const GET_AVAILABLE_PRODUCTS_COUNT_FAILURE =
  'GET_AVAILABLE_PRODUCTS_COUNT_FAILURE';

export const getAvailableProductsCountRequest = filters => ({
  filters,
  type: GET_AVAILABLE_PRODUCTS_COUNT_REQUEST,
});

export const getAvailableProductsCountSuccess = payload => ({
  payload,
  type: GET_AVAILABLE_PRODUCTS_COUNT_SUCCESS,
});

export const getAvailableProductsCountFailure = payload => ({
  payload,
  type: GET_AVAILABLE_PRODUCTS_COUNT_FAILURE,
});

export const GET_PRODUCTS_IN_STORE_REQUEST = 'GET_PRODUCTS_IN_STORE_REQUEST';
export const GET_PRODUCTS_IN_STORE_SUCCESS = 'GET_PRODUCTS_IN_STORE_SUCCESS';
export const GET_PRODUCTS_IN_STORE_FAILURE = 'GET_PRODUCTS_IN_STORE_FAILURE';

export const getProductsInStoreRequest = id => ({
  id,
  type: GET_PRODUCTS_IN_STORE_REQUEST,
});

export const getProductsInStoreSuccess = payload => ({
  payload,
  type: GET_PRODUCTS_IN_STORE_SUCCESS,
});

export const getProductsInStoreFailure = error => ({
  error,
  type: GET_PRODUCTS_IN_STORE_FAILURE,
});

export const SET_LAYOUT_PREFERENCE = 'SET_LAYOUT_PREFERENCE';

export const setLayoutPreference = layoutPreference => ({
  layoutPreference,
  type: SET_LAYOUT_PREFERENCE,
});

export const GET_OFFER_PRODUCTS_COUNT_REQUEST =
  'GET_OFFER_PRODUCTS_COUNT_REQUEST';
export const GET_OFFER_PRODUCTS_COUNT_SUCCESS =
  'GET_OFFER_PRODUCTS_COUNT_SUCCESS';
export const GET_OFFER_PRODUCTS_COUNT_FAILURE =
  'GET_OFFER_PRODUCTS_COUNT_FAILURE';

export const getOfferProductsCountRequest = () => ({
  type: GET_OFFER_PRODUCTS_COUNT_REQUEST,
});

export const getOfferProductsCountFailure = () => ({
  type: GET_OFFER_PRODUCTS_COUNT_FAILURE,
});

export const getOfferProductsCountSuccess = payload => ({
  payload,
  type: GET_OFFER_PRODUCTS_COUNT_SUCCESS,
});

export const GET_SALE_PRODUCTS_COUNT_REQUEST =
  'GET_SALE_PRODUCTS_COUNT_REQUEST';
export const GET_SALE_PRODUCTS_COUNT_SUCCESS =
  'GET_SALE_PRODUCTS_COUNT_SUCCESS';
export const GET_SALE_PRODUCTS_COUNT_FAILURE =
  'GET_SALE_PRODUCTS_COUNT_FAILURE';

export const getSaleProductsCountRequest = () => ({
  type: GET_SALE_PRODUCTS_COUNT_REQUEST,
});

export const getSaleProductsCountFailure = () => ({
  type: GET_SALE_PRODUCTS_COUNT_FAILURE,
});

export const getSaleProductsCountSuccess = payload => ({
  payload,
  type: GET_SALE_PRODUCTS_COUNT_SUCCESS,
});

export const GET_PRODUCT_DESCRIPTION_REQUEST = 'GET_PRODUCT_DESCRIPTION';
export const GET_PRODUCT_DESCRIPTION_SUCCESS =
  'GET_PRODUCT_DESCRIPTION_SUCCESS';
export const GET_PRODUCT_DESCRIPTION_FAILURE =
  'GET_PRODUCT_DESCRIPTION_FAILURE';

export const getProductDescriptionRequest = () => ({
  type: GET_PRODUCT_DESCRIPTION_REQUEST,
});

export const getProductDescriptionFailure = () => ({
  type: GET_PRODUCT_DESCRIPTION_FAILURE,
});

export const getProductDescriptionSuccess = payload => ({
  payload,
  type: GET_PRODUCT_DESCRIPTION_SUCCESS,
});

export const GET_LATEST_NEWS_REQUEST = 'GET_LATEST_NEWS';
export const GET_LATEST_NEWS_SUCCESS = 'GET_LATEST_NEWS_SUCCESS';
export const GET_LATEST_NEWS_FAILURE = 'GET_LATEST_NEWS_FAILURE';

export const getLatestNewsRequest = () => ({ type: GET_LATEST_NEWS_REQUEST });

export const getLatestNewsFailure = () => ({ type: GET_LATEST_NEWS_FAILURE });

export const getLatestNewsSuccess = payload => ({
  payload,
  type: GET_LATEST_NEWS_SUCCESS,
});

export const GET_LATEST_LOOKBOOK_REQUEST = 'GET_LATEST_LOOKBOOK';
export const GET_LATEST_LOOKBOOK_SUCCESS = 'GET_LATEST_LOOKBOOK_SUCCESS';
export const GET_LATEST_LOOKBOOK_FAILURE = 'GET_LATEST_LOOKBOOK_FAILURE';

export const getLatestLookbookRequest = () => ({
  type: GET_LATEST_LOOKBOOK_REQUEST,
});

export const getLatestLookbookFailure = () => ({
  type: GET_LATEST_LOOKBOOK_FAILURE,
});

export const getLatestLookbookSuccess = payload => ({
  payload,
  type: GET_LATEST_LOOKBOOK_SUCCESS,
});
