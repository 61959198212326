import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '@actions/profileImage';

import { IMAGE_PLACEHOLDER } from '@common/images';

const initialState = {
  image: IMAGE_PLACEHOLDER,
  isFetching: false,
  isPlaceholder: true,
  isUploading: false,
};

export default function profileImageReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...action.payload.profileImage,
      };

    case actions.CLEAR_PROFILE_PICTURE:
      return { ...initialState };

    case actions.REMOVE_PROFILE_PICTURE_REQUEST:
    case actions.PROFILE_PICTURE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actions.PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        image: action.payload.image?.url || IMAGE_PLACEHOLDER,
        isFetching: false,
        isPlaceholder: !action.payload.image,
      };

    case actions.PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case actions.REMOVE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        image: IMAGE_PLACEHOLDER,
        isPlaceholder: true,
      };

    case actions.REMOVE_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case actions.UPLOAD_PROFILE_PICTURE_REQUEST:
      return {
        ...state,
        isUploading: true,
      };

    case actions.UPLOAD_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        image: action.payload.image?.url || IMAGE_PLACEHOLDER,
        isPlaceholder: false,
        isUploading: false,
      };

    case actions.UPLOAD_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        isUploading: false,
      };

    default:
      return state;
  }
}
