export const GET_AREAS_OF_OPERATION_REQUEST = 'GET_AREAS_OF_OPERATION_REQUEST';
export const GET_AREAS_OF_OPERATION_SUCCESS = 'GET_AREAS_OF_OPERATION_SUCCESS';
export const GET_AREAS_OF_OPERATION_FAILURE = 'GET_AREAS_OF_OPERATION_FAILURE';

export const getAreasOfOperationRequest = () => ({
  type: GET_AREAS_OF_OPERATION_REQUEST,
});

export const getAreasOfOperationSuccess = payload => ({
  payload,
  type: GET_AREAS_OF_OPERATION_SUCCESS,
});

export const getAreasOfOperationFailure = payload => ({
  payload,
  type: GET_AREAS_OF_OPERATION_FAILURE,
});

export const GET_JOB_OFFERS_LIST_REQUEST = 'GET_JOB_OFFERS_LIST_REQUEST';
export const GET_JOB_OFFERS_LIST_SUCCESS = 'GET_JOB_OFFERS_LIST_SUCCESS';
export const GET_JOB_OFFERS_LIST_FAILURE = 'GET_JOB_OFFERS_LIST_FAILURE';

export const getJobOffersListRequest = areaOfOperationId => ({
  payload: {
    areaOfOperationId,
  },
  type: GET_JOB_OFFERS_LIST_REQUEST,
});

export const getJobOffersListSuccess = (areaOfOperationId, data) => ({
  payload: {
    areaOfOperationId,
    data,
  },
  type: GET_JOB_OFFERS_LIST_SUCCESS,
});

export const getJobOffersListFailure = (areaOfOperationId, error) => ({
  payload: {
    areaOfOperationId,
    error,
  },
  type: GET_JOB_OFFERS_LIST_FAILURE,
});
