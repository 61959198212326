import styled from 'styled-components';

import { hexToRgba, getThemeColor } from '@utils/styled';

import { variants } from '@common/alert';
import CloseButton from '@common/components/CloseButton';
import Typography from '@common/components/Typography';

function variantToBackgroundColor({ variant }) {
  switch (variant) {
    case variants.SUCCESS:
      return getThemeColor('hue.green');
    case variants.ERROR:
    default:
      return getThemeColor('hue.red');
  }
}

function variantToColor({ variant }) {
  switch (variant) {
    default:
      return getThemeColor('grey.100');
  }
}

export const Wrapper = styled.div`
  position: fixed;
  z-index: 18;
  display: flex;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    display: block;
    margin-right: -100%;
    margin-left: -100%;
    background-color: ${variantToBackgroundColor};
  }
`;

export const WrapperInner = styled.div`
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'equal', 5)};
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 0)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'equal', 5)};
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'widthBased', 0)};
  background-color: ${variantToBackgroundColor};
  color: ${variantToColor};
`;

export const Content = styled.div`
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
`;

export const Text = styled(Typography).attrs({ variant: 'body3' })`
  align-self: center;
  margin: 0;
  white-space: pre-line;
`;

export const StyledCloseButton = styled(CloseButton)`
  position: initial;
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'equal', 3)};
  border-color: ${({ theme }) =>
    hexToRgba(getThemeColor('grey.0')({ theme }), 0)};
  background: none;
  background-color: ${({ theme }) =>
    hexToRgba(getThemeColor('grey.0')({ theme }), 0.15)};
  cursor: pointer;

  svg {
    fill: ${getThemeColor('grey.100')};
  }

  &:hover,
  &:focus,
  &:active {
    border-color: ${({ theme }) =>
      hexToRgba(getThemeColor('grey.20')({ theme }), 0)};
    background-color: ${({ theme }) =>
      hexToRgba(getThemeColor('grey.0')({ theme }), 0.15)};
  }
`;
