export const GET_GOODIES_REQUEST = 'GET_GOODIES_REQUEST';
export const GET_GOODIES_SUCCESS = 'GET_GOODIES_SUCCESS';
export const GET_GOODIES_FAILURE = 'GET_GOODIES_FAILURE';

export const getGoodiesRequest = () => ({
  type: GET_GOODIES_REQUEST,
});

export const getGoodiesSuccess = payload => ({
  payload,
  type: GET_GOODIES_SUCCESS,
});

export const getGoodiesFailure = payload => ({
  payload,
  type: GET_GOODIES_FAILURE,
});

export const GET_GOODIES_DETAILS_REQUEST = 'GET_GOODIES_DETAILS_REQUEST';
export const GET_GOODIES_DETAILS_SUCCESS = 'GET_GOODIES_DETAILS_SUCCESS';
export const GET_GOODIES_DETAILS_FAILURE = 'GET_GOODIES_DETAILS_FAILURE';

export const getGoodiesDetailsRequest = id => ({
  id,
  type: GET_GOODIES_DETAILS_REQUEST,
});

export const getGoodiesDetailsSuccess = (id, payload) => ({
  id,
  payload,
  type: GET_GOODIES_DETAILS_SUCCESS,
});

export const getGoodiesDetailsFailure = (id, payload) => ({
  id,
  payload,
  type: GET_GOODIES_DETAILS_FAILURE,
});

export const OPEN_GOODIE_REQUEST = 'OPEN_GOODIE_REQUEST';
export const OPEN_GOODIE_SUCCESS = 'OPEN_GOODIE_SUCCESS';
export const OPEN_GOODIE_FAILURE = 'OPEN_GOODIE_FAILURE';

export const openGoodieRequest = payload => ({
  payload,
  type: OPEN_GOODIE_REQUEST,
});

export const openGoodieSuccess = payload => ({
  payload,
  type: OPEN_GOODIE_SUCCESS,
});

export const openGoodieFailure = payload => ({
  payload,
  type: OPEN_GOODIE_FAILURE,
});

export const CLEAR_GOODIES = 'CLEAR_GOODIES';

export const clearGoodies = () => ({
  type: CLEAR_GOODIES,
});
