import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '../actions/vouchers';

const VOUCHERS_PER_PAGE = 12;

const initialState = {
  infoModal: {
    data: {},
    isFetching: false,
  },
  vouchers: {
    hasMore: true,
    isFetching: true,
    offset: 0,
    results: [],
  },
};

export default function vouchersReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.vouchers,
      };

    case actions.CLEAR_VOUCHERS:
      return {
        ...state,
        vouchers: {
          ...initialState.vouchers,
        },
      };

    case actions.GET_VOUCHERS_REQUEST:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          isFetching: true,
        },
      };

    case actions.GET_VOUCHERS_SUCCESS:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          hasMore: Boolean(action.payload.next),
          isFetching: false,
          offset: state.vouchers.offset + VOUCHERS_PER_PAGE,
          results: [...state.vouchers.results, ...action.payload.results],
        },
      };

    case actions.GET_VIP_INFO_MODAL_REQUEST:
      return {
        ...state,
        infoModal: {
          ...state.infoModal,
          isFetching: true,
        },
      };

    case actions.GET_VIP_INFO_MODAL_SUCCESS:
      return {
        ...state,
        infoModal: {
          ...state.infoModal,
          data: action.payload,
          isFetching: false,
        },
      };

    case actions.GET_VIP_INFO_MODAL_FAILURE:
      return {
        ...state,
        infoModal: {
          ...state.infoModal,
          isFetching: false,
        },
      };

    default:
      return state;
  }
}
