import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={18} height={22} viewBox="0 0 18 22" {...props}>
      <path d="M16 0a2 2 0 012 2v18a2 2 0 01-2 2H2a2 2 0 01-2-2v-6h2v5.4c0 .3.3.6.6.6h12.8c.3 0 .6-.3.6-.6V2.6c0-.3-.3-.6-.6-.6H2.6c-.3 0-.6.3-.6.6V8H0V2C0 .9 1 0 2 0zm-6 7l4 4-4 4v-3H2v-2h8V7z" />
    </svg>
  );
}

export default SvgComponent;
