import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={24} height={18} viewBox="0 0 24 18" {...props}>
      <defs>
        <path
          d="M20,0 C22.21,0 24,1.791 24,4 L24,4 L24,14 C24,16.21 22.21,18 20,18 L20,18 L4,18 C1.791,18 -1.77635684e-15,16.21 -1.77635684e-15,14 L-1.77635684e-15,14 L-1.77635684e-15,4 C-1.77635684e-15,1.791 1.791,0 4,0 L4,0 Z M20,1.301 L4,1.301 C2.512,1.301 1.301,2.512 1.301,4 L1.301,4 L1.301,14 C1.301,15.489 2.512,16.7 4,16.7 L4,16.7 L20,16.7 C21.489,16.7 22.7,15.489 22.7,14 L22.7,14 L22.7,4 C22.7,2.512 21.489,1.301 20,1.301 L20,1.301 Z M20.0002,4.0004 L20.0002,14.0004 L19.0002,14.0004 L19.0002,4.0004 L20.0002,4.0004 Z M18.0002,4.0004 L18.0002,14.0004 L16.0002,14.0004 L16.0002,4.0004 L18.0002,4.0004 Z M12.0002,10.0004 L12.0002,14.0004 L11.0002,14.0004 L11.0002,10.0004 L12.0002,10.0004 Z M15.0002,8.0004 L15.0002,14.0004 L13.0002,14.0004 L13.0002,8.0004 L15.0002,8.0004 Z M10.0002,10.0004 L10.0002,14.0004 L8.0002,14.0004 L8.0002,10.0004 L10.0002,10.0004 Z M7.0002,10.0004 L7.0002,14.0004 L6.0002,14.0004 L6.0002,10.0004 L7.0002,10.0004 Z M5.0002,10.0004 L5.0002,14.0004 L4.0002,14.0004 L4.0002,10.0004 L5.0002,10.0004 Z M12.8062,4.0002 C13.7812,4.0002 14.4282,4.6452 14.4282,5.5342 C14.4282,6.37947778 13.851092,6.99640988 12.9656083,7.06234901 L12.8062,7.0682 L11.9222,7.0682 L11.9222,8.9212 L10.9612,8.9212 L10.9612,4.0002 L12.8062,4.0002 Z M5.0513,4 L6.3393,7.657 L7.6263,4 L8.6433,4 L6.8123,8.921 L5.8243,8.921 L3.9993,4 L5.0513,4 Z M10.0432,3.9994 L10.0432,8.9204 L9.0682,8.9204 L9.0682,3.9994 L10.0432,3.9994 Z M12.6882,4.8332 L11.9292,4.8332 L11.9292,6.2422 L12.6882,6.2422 C13.1672,6.2422 13.4602,5.9642 13.4602,5.5412 C13.4602,5.1032 13.1672,4.8332 12.6882,4.8332 Z"
          id="path-1"
        />
      </defs>
      <g
        id="Atoms"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icon">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <use fill="#000000" xlinkHref="#path-1" />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
