const swissNumberFormat = new Intl.NumberFormat('de-CH');

function formatNumber(number) {
  return swissNumberFormat.format(number).replace(/’/g, "'");
}

function numberToTwoDecimals(number) {
  return Math.round(number * 100) / 100;
}

function numberToSup(number) {
  const parts = number.toFixed(2).toString().split('.');

  const [main, cents] = parts;

  return cents === '00' ? `${main}.–` : `${main}<sup>${cents}</sup>`;
}

const numberToSupWithCurrency = number => `CHF ${numberToSup(number)}`;

function formatPhoneNumber(phoneNumber) {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  let match;

  if (phoneNumber.startsWith('+')) {
    match = cleaned.match(/^(\d{2})(\d{2})(\d{3})(\d{0,2})(\d{0,9})?/);
  } else {
    match = cleaned.match(/^(\d{2})(\d{3})(\d{2})(\d{0,3})(\d{0,9})?/);
  }

  if (match) {
    if (phoneNumber.startsWith('+')) {
      return [
        '+',
        match[1],
        ' ',
        match[2],
        ' ',
        match[3],
        ' ',
        match[4],
        ' ',
        match[5],
      ].join('');
    }

    return [
      '+41',
      ' ',
      match[1],
      ' ',
      match[2],
      ' ',
      match[3],
      ' ',
      match[4],
      ' ',
      match[5],
    ].join('');
  }

  return null;
}

export {
  formatNumber,
  formatPhoneNumber,
  numberToSup,
  numberToSupWithCurrency,
  numberToTwoDecimals,
};
