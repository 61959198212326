export default class AttributesSet {
  constructor() {
    this.attributes = {};

    this.setBodyAttribute = this.setBodyAttribute.bind(this);
    this.removeBodyAttribute = this.removeBodyAttribute.bind(this);
    this.getBodyAttributes = this.getBodyAttributes.bind(this);
  }

  setBodyAttribute(attribute, value) {
    this.attributes[attribute] = value;
  }

  removeBodyAttribute(attribute) {
    delete this.attributes[attribute];
  }

  getBodyAttributes() {
    return { ...this.attributes };
  }
}
