import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
      <defs>
        <path
          id="prefix__grid_layout"
          d="M19 11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1h-7a1 1 0 01-1-1v-7c0-.6.4-1 1-1h7zM8 11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1H1a1 1 0 01-1-1v-7c0-.6.4-1 1-1h7zM19 0c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1h-7a1 1 0 01-1-1V1c0-.6.4-1 1-1h7zM8 0c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1H1a1 1 0 01-1-1V1c0-.6.4-1 1-1h7z"
        />
      </defs>
      <use xlinkHref="#prefix__grid_layout" />
    </svg>
  );
}

export default SvgComponent;
