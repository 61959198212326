import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={16} height={20} viewBox="0 0 16 20" {...props}>
      <path d="M14 13.5v-5c0-3.1-1.6-5.6-4.5-6.3v-.7C9.5.7 8.8 0 8 0S6.5.7 6.5 1.5v.7C3.6 2.9 2 5.4 2 8.5v5l-2 2v1h16v-1l-2-2zm-6 6c1.1 0 2-.9 2-2H6c0 1.1.9 2 2 2z" />
    </svg>
  );
}

export default SvgComponent;
