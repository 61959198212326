import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="#202226" {...props}>
      <path d="M1.6,14.5454545 L14.4,14.5454545 L14.4,5.09090909 L1.6,5.09090909 L1.6,14.5454545 Z M14.4,1.45454545 L13.6,1.45454545 L13.6,0 L12,0 L12,1.45454545 L4,1.45454545 L4,0 L2.4,0 L2.4,1.45454545 L1.6,1.45454545 C0.72,1.45454545 0,2.10909091 0,2.90909091 L0,14.5454545 C0,15.3454545 0.72,16 1.6,16 L14.4,16 C15.28,16 16,15.3454545 16,14.5454545 L16,2.90909091 C16,2.10909091 15.28,1.45454545 14.4,1.45454545 L14.4,1.45454545 Z" />
    </svg>
  );
}

export default SvgComponent;
