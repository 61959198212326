import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
      <path d="M10,0 C15.52,0 20,4.48 20,10 C20,11.289 19.747,12.518 19.302,13.65 C18.58,12.94 17.593,12.5 16.5,12.5 C16.1,12.5 15.72,12.577 15.354,12.687 C14.142,11.523 11.504,10.9 10,10.9 C8,10.9 4.03,11.99 4,13.98 C5.29,15.92 7.5,17.2 10,17.2 C10.888,17.2 11.737,17.031 12.523,16.736 C12.583,17.732 12.993,18.634 13.65,19.302 C12.518,19.747 11.289,20 10,20 C4.48,20 0,15.52 0,10 C0,4.48 4.48,0 10,0 Z M17,14 L17,16 L19,16 L19,17 L17,17 L17,19 L16,19 L16,17 L14,17 L14,16 L16,16 L16,14 L17,14 Z M10,3 C8.34,3 7,4.34 7,6 C7,7.66 8.34,9 10,9 C11.66,9 13,7.66 13,6 C13,4.34 11.66,3 10,3 Z" />
    </svg>
  );
}

export default SvgComponent;
