import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={30} height={35} viewBox="0 0 30 35" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M13.846 19.29V35H1.505C.702 35 .1 34.304.1 33.509v-14.22h13.746zm16.054 0v14.219c0 .795-.602 1.491-1.405 1.491h-12.04V19.29H29.9zm-16.054-8.95v6.762H0v-5.27c0-.795.502-1.491 1.204-1.491h12.642zm14.95 0c.602 0 1.204.697 1.204 1.492v5.27H16.455v-6.761zM20.669 0c2.508 0 4.515 1.989 4.615 4.474 0 2.486-2.006 4.674-4.515 4.674H10.234c-2.508 0-4.515-2.188-4.515-4.674C5.72 1.99 7.726 0 10.234 0c1.706 0 3.11.895 3.913 2.386l1.305 2.586 1.304-2.586.096-.19C17.589.818 19.037 0 20.669 0zm.1 1.69c-1.003 0-1.906.597-2.408 1.492l-.702 1.292-.803 1.591-.501 1.392h4.414c1.505 0 2.71-1.491 2.71-2.983 0-1.59-1.205-2.784-2.71-2.784zm-10.736 0c-1.505 0-2.709 1.194-2.809 2.784 0 1.492 1.204 2.983 2.71 2.983h4.414l-.502-1.392-.702-1.59-.703-1.293c-.501-.895-1.404-1.492-2.408-1.492z"
      />
    </svg>
  );
}

export default SvgComponent;
