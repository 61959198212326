import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '../actions/customerInfo';

const initialState = {
  data: {
    badgeCount: 0,
    cardNr: '',
    firstname: '',
    lastname: '',
    points: 0,
    profileImage: '',
    promotionGiftBadge: 0,
    voucherBadge: 0,
    voucherTotal: 0,
  },
  isFetching: false,
};

export default function customerInfoReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...action.payload.customerInfo,
      };

    case actions.CLEAR_CUSTOMER_INFO:
      return { ...initialState };

    case actions.GET_CUSTOMER_INFO_REQUEST:
      return { ...state, isFetching: true };

    case actions.GET_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          points: action.payload.vipPoints,
          profileImage: action.payload.image,
        },
        isFetching: false,
      };

    case actions.GET_CUSTOMER_INFO_FAILURE:
      return { ...state, isFetching: false };

    case actions.GET_CUSTOMER_BADGE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, badgeCount: action.payload.badgeCount },
        isFetching: false,
      };

    default:
      return state;
  }
}
