import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '../actions/goodies';

const GOODIES_PER_PAGE = 12;

const initialState = {
  goodiesDetails: {},
  isFetching: true,
  isFetchingDetails: false,
  results: [],
  offset: 0,
  hasMore: true,
};

export default function goodiesReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...action.payload.goodies,
      };

    case actions.CLEAR_GOODIES:
      return { ...initialState };

    case actions.GET_GOODIES_REQUEST:
      return { ...state, isFetching: true };

    case actions.GET_GOODIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasMore: Boolean(action.payload.next),
        offset: state.offset + GOODIES_PER_PAGE,
        results: [...state.results, ...action.payload.results],
      };

    case actions.GET_GOODIES_FAILURE:
      return { ...state, hasMore: false, isFetching: false };

    case actions.GET_GOODIES_DETAILS_REQUEST:
      return { ...state, isFetchingDetails: true };

    case actions.GET_GOODIES_DETAILS_SUCCESS:
      return {
        ...state,
        goodiesDetails: {
          ...state.goodiesDetails,
          [action.payload.id]: action.payload,
        },
        isFetchingDetails: false,
      };

    case actions.GET_GOODIES_DETAILS_FAILURE:
      return { ...state, isFetchingDetails: false };

    case actions.OPEN_GOODIE_REQUEST:
      return {
        ...state,
        results: state.results.map(goodie =>
          goodie.id === action.payload.id
            ? {
                ...goodie,
                isOpening: true,
              }
            : goodie,
        ),
      };

    case actions.OPEN_GOODIE_SUCCESS:
      return {
        ...state,
        results: state.results.map(goodie =>
          goodie.id === action.payload.id
            ? {
                ...goodie,
                isOpen: true,
                isOpening: false,
              }
            : goodie,
        ),
      };

    case actions.OPEN_GOODIE_FAILURE:
      return {
        ...state,
        results: state.results.map(goodie =>
          goodie.id === action.payload.id
            ? {
                ...goodie,
                isOpen: false,
                isOpening: false,
              }
            : goodie,
        ),
      };

    default:
      return state;
  }
}
