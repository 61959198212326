import styled, { css } from 'styled-components';

import {
  getThemeTransition,
  media,
  getThemeColor,
  hexToRgba,
} from '@utils/styled';

import { BUTTON_VARIANTS } from '@common/components/Button/common';

const variantToBackgroundColor =
  state =>
  ({ variant, theme }) => {
    switch (variant) {
      case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL:
      case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90: {
        const colors = {
          active: getThemeColor('bluegrey.90'),
          disabled: getThemeColor('bluegrey.90'),
          focus: '#eaeef3',
          hover: '#eaeef3',
          normal: getThemeColor('bluegrey.90'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL_INVERTED:
      case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_INVERTED: {
        const colors = {
          active: getThemeColor('bluegrey.90'),
          disabled: getThemeColor('bluegrey.90'),
          focus: '#d2d4d9',
          hover: '#d2d4d9',
          normal: getThemeColor('bluegrey.90'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_2_GREY_20_SMALL:
      case BUTTON_VARIANTS.LEVEL_2_GREY_20: {
        const colors = {
          active: getThemeColor('grey.20'),
          disabled: '#5a5a5a',
          focus: '#616161',
          hover: '#616161',
          normal: getThemeColor('grey.20'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15_SMALL:
      case BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15: {
        const colors = {
          active: getThemeColor('bluegrey.90'),
          disabled: getThemeColor('grey.100'),
          focus: getThemeColor('bluegrey.90'),
          hover: getThemeColor('bluegrey.90'),
          normal: 'transparent',
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_1_RED_SMALL:
      case BUTTON_VARIANTS.LEVEL_1_RED: {
        const colors = {
          active: getThemeColor('hue.red'),
          disabled: '#c8686f',
          focus: '#f7344a',
          hover: '#f7344a',
          normal: getThemeColor('hue.red'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_1_FACEBOOK_BLUE_SMALL:
      case BUTTON_VARIANTS.LEVEL_1_FACEBOOK_BLUE: {
        const colors = {
          active: getThemeColor('hue.blue'),
          disabled: '#4da6ff',
          focus: '#4da6ff',
          hover: '#4da6ff',
          normal: getThemeColor('hue.blue'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_4_GREEN_SMALL:
      case BUTTON_VARIANTS.LEVEL_4_GREEN:
      case BUTTON_VARIANTS.LEVEL_4_BLUEGREY_15_SMALL:
      case BUTTON_VARIANTS.LEVEL_4_BLUEGREY_15:
      case BUTTON_VARIANTS.LEVEL_4_RED_SMALL:
      case BUTTON_VARIANTS.LEVEL_4_RED: {
        const colors = {
          active: getThemeColor('transparent'),
          disabled: getThemeColor('transparent'),
          focus: getThemeColor('transparent'),
          hover: getThemeColor('transparent'),
          normal: getThemeColor('transparent'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_3_GREY_100: {
        const colors = {
          active: getThemeColor('bluegrey.90'),
          disabled: getThemeColor('bluegrey.95'),
          focus: getThemeColor('bluegrey.90'),
          hover: getThemeColor('bluegrey.90'),
          normal: getThemeColor('grey.100'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_2_GREY_100_15:
      case BUTTON_VARIANTS.LEVEL_2_GREY_100_15_SMALL: {
        const colors = {
          active: hexToRgba(getThemeColor('grey.0')({ theme }), 0.15),
          disabled: hexToRgba(getThemeColor('grey.0')({ theme }), 0.1),
          focus: hexToRgba(getThemeColor('grey.0')({ theme }), 0.25),
          hover: hexToRgba(getThemeColor('grey.0')({ theme }), 0.25),
          normal: hexToRgba(getThemeColor('grey.0')({ theme }), 0.15),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_1_GREEN_SMALL:
      case BUTTON_VARIANTS.LEVEL_1_GREEN:
      default: {
        const colors = {
          active: getThemeColor('hue.darkGreen'),
          disabled: getThemeColor('bluegrey.95'),
          focus: getThemeColor('hue.darkGreen'),
          hover: getThemeColor('hue.darkGreen'),
          normal: getThemeColor('hue.green'),
        };

        return colors[state];
      }
    }
  };

const variantToTextColor =
  state =>
  ({ variant }) => {
    switch (variant) {
      case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL:
      case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL_INVERTED:
      case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_INVERTED:
      case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90: {
        const colors = {
          active: getThemeColor('bluegrey.15'),
          disabled: '#9a9a9a',
          focus: getThemeColor('bluegrey.15'),
          hover: getThemeColor('bluegrey.15'),
          normal: getThemeColor('bluegrey.15'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_2_GREY_20_SMALL:
      case BUTTON_VARIANTS.LEVEL_2_GREY_20: {
        const colors = {
          active: getThemeColor('grey.100'),
          disabled: '#dadada',
          focus: getThemeColor('grey.100'),
          hover: getThemeColor('grey.100'),
          normal: getThemeColor('grey.100'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15_SMALL:
      case BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15:
      case BUTTON_VARIANTS.LEVEL_3_GREY_100: {
        const colors = {
          active: getThemeColor('bluegrey.15'),
          disabled: '#949494',
          focus: getThemeColor('bluegrey.15'),
          hover: getThemeColor('bluegrey.15'),
          normal: getThemeColor('bluegrey.15'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_1_RED_SMALL:
      case BUTTON_VARIANTS.LEVEL_1_RED: {
        const colors = {
          active: getThemeColor('grey.100'),
          disabled: '#f6f6f6',
          focus: getThemeColor('grey.100'),
          hover: getThemeColor('grey.100'),
          normal: getThemeColor('grey.100'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_4_GREEN_SMALL:
      case BUTTON_VARIANTS.LEVEL_4_GREEN: {
        const colors = {
          active: getThemeColor('hue.green'),
          disabled: getThemeColor('hue.green'),
          focus: getThemeColor('hue.green'),
          hover: '#8dd737',
          normal: getThemeColor('hue.green'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_4_BLUEGREY_15_SMALL:
      case BUTTON_VARIANTS.LEVEL_4_BLUEGREY_15: {
        const colors = {
          active: getThemeColor('bluegrey.15'),
          disabled: getThemeColor('bluegrey.15'),
          focus: getThemeColor('bluegrey.15'),
          hover: '#4f545f',
          normal: getThemeColor('bluegrey.15'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_4_RED_SMALL:
      case BUTTON_VARIANTS.LEVEL_4_RED: {
        const colors = {
          active: getThemeColor('hue.red'),
          disabled: getThemeColor('hue.red'),
          focus: getThemeColor('hue.red'),
          hover: '#f66a78',
          normal: getThemeColor('hue.red'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_1_GREEN_SMALL:
      case BUTTON_VARIANTS.LEVEL_1_GREEN:
      default: {
        const colors = {
          active: getThemeColor('grey.100'),
          disabled: getThemeColor('bluegrey.70'),
          focus: getThemeColor('grey.100'),
          hover: getThemeColor('grey.100'),
          normal: getThemeColor('grey.100'),
        };

        return colors[state];
      }
    }
  };

const variantToBorderColor =
  state =>
  ({ variant }) => {
    switch (variant) {
      case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL:
      case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90: {
        const colors = {
          active: getThemeColor('bluegrey.90'),
          disabled: getThemeColor('bluegrey.90'),
          focus: '#eaeef3',
          hover: '#eaeef3',
          normal: getThemeColor('bluegrey.90'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL_INVERTED:
      case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_INVERTED: {
        const colors = {
          active: getThemeColor('bluegrey.90'),
          disabled: getThemeColor('bluegrey.90'),
          focus: '#d2d4d9',
          hover: '#d2d4d9',
          normal: getThemeColor('bluegrey.90'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_2_GREY_20_SMALL:
      case BUTTON_VARIANTS.LEVEL_2_GREY_20: {
        const colors = {
          active: getThemeColor('grey.20'),
          disabled: '#5a5a5a',
          focus: '#616161',
          hover: '#616161',
          normal: getThemeColor('grey.20'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15_SMALL:
      case BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15:
      case BUTTON_VARIANTS.LEVEL_3_GREY_100: {
        const colors = {
          active: getThemeColor('bluegrey.15'),
          disabled: '#949494',
          focus: getThemeColor('bluegrey.15'),
          hover: getThemeColor('bluegrey.15'),
          normal: getThemeColor('bluegrey.15'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_1_RED_SMALL:
      case BUTTON_VARIANTS.LEVEL_1_RED: {
        const colors = {
          active: getThemeColor('hue.red'),
          disabled: '#c8686f',
          focus: '#f7344a',
          hover: '#f7344a',
          normal: getThemeColor('hue.red'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_4_GREEN_SMALL:
      case BUTTON_VARIANTS.LEVEL_4_GREEN:
      case BUTTON_VARIANTS.LEVEL_4_BLUEGREY_15_SMALL:
      case BUTTON_VARIANTS.LEVEL_4_BLUEGREY_15:
      case BUTTON_VARIANTS.LEVEL_4_RED_SMALL:
      case BUTTON_VARIANTS.LEVEL_4_RED: {
        const colors = {
          active: getThemeColor('transparent'),
          disabled: getThemeColor('transparent'),
          focus: getThemeColor('transparent'),
          hover: getThemeColor('transparent'),
          normal: getThemeColor('transparent'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_1_FACEBOOK_BLUE_SMALL:
      case BUTTON_VARIANTS.LEVEL_1_FACEBOOK_BLUE: {
        const colors = {
          active: getThemeColor('hue.blue'),
          disabled: '#4da6ff',
          focus: '#4da6ff',
          hover: '#4da6ff',
          normal: getThemeColor('hue.blue'),
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_2_GREY_100_15:
      case BUTTON_VARIANTS.LEVEL_2_GREY_100_15_SMALL: {
        const colors = {
          active: 'transparent',
          disabled: 'transparent',
          focus: 'transparent',
          hover: 'transparent',
          normal: 'transparent',
        };

        return colors[state];
      }

      case BUTTON_VARIANTS.LEVEL_1_GREEN_SMALL:
      case BUTTON_VARIANTS.LEVEL_1_GREEN:
      default: {
        const colors = {
          active: getThemeColor('hue.darkGreen'),
          disabled: getThemeColor('bluegrey.80'),
          focus: getThemeColor('hue.darkGreen'),
          hover: getThemeColor('hue.darkGreen'),
          normal: getThemeColor('hue.green'),
        };

        return colors[state];
      }
    }
  };

function variantToFluidFontSize({ theme, variant }) {
  switch (variant) {
    case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL:
    case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL_INVERTED:
    case BUTTON_VARIANTS.LEVEL_2_GREY_20_SMALL:
    case BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15_SMALL:
    case BUTTON_VARIANTS.LEVEL_4_BLUEGREY_15_SMALL:
    case BUTTON_VARIANTS.LEVEL_1_GREEN_SMALL:
    case BUTTON_VARIANTS.LEVEL_4_GREEN_SMALL:
    case BUTTON_VARIANTS.LEVEL_1_RED_SMALL:
    case BUTTON_VARIANTS.LEVEL_1_FACEBOOK_BLUE_SMALL:
    case BUTTON_VARIANTS.LEVEL_2_GREY_100_15_SMALL:
      return theme.getFluidFont('body2');
    case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90:
    case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_INVERTED:
    case BUTTON_VARIANTS.LEVEL_2_GREY_20:
    case BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15:
    case BUTTON_VARIANTS.LEVEL_1_GREEN:
    case BUTTON_VARIANTS.LEVEL_1_RED:
    case BUTTON_VARIANTS.LEVEL_1_FACEBOOK_BLUE:
    case BUTTON_VARIANTS.LEVEL_3_GREY_100:
    case BUTTON_VARIANTS.LEVEL_2_GREY_100_15:
    default:
      return theme.getFluidFont('paragraph1');
  }
}

function variantToHeight({ variant }) {
  switch (variant) {
    case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL:
    case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL_INVERTED:
    case BUTTON_VARIANTS.LEVEL_2_GREY_20_SMALL:
    case BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15_SMALL:
    case BUTTON_VARIANTS.LEVEL_1_GREEN_SMALL:
    case BUTTON_VARIANTS.LEVEL_1_RED_SMALL:
    case BUTTON_VARIANTS.LEVEL_1_FACEBOOK_BLUE_SMALL:
    case BUTTON_VARIANTS.LEVEL_4_GREEN_SMALL:
    case BUTTON_VARIANTS.LEVEL_4_BLUEGREY_15_SMALL:
    case BUTTON_VARIANTS.LEVEL_4_RED_SMALL:
    case BUTTON_VARIANTS.LEVEL_2_GREY_100_15_SMALL:
      return '4rem';
    case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90:
    case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_INVERTED:
    case BUTTON_VARIANTS.LEVEL_2_GREY_20:
    case BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15:
    case BUTTON_VARIANTS.LEVEL_1_GREEN:
    case BUTTON_VARIANTS.LEVEL_1_RED:
    case BUTTON_VARIANTS.LEVEL_1_FACEBOOK_BLUE:
    case BUTTON_VARIANTS.LEVEL_4_GREEN:
    case BUTTON_VARIANTS.LEVEL_4_BLUEGREY_15:
    case BUTTON_VARIANTS.LEVEL_4_RED:
    case BUTTON_VARIANTS.LEVEL_3_GREY_100:
    case BUTTON_VARIANTS.LEVEL_2_GREY_100_15:
    default:
      return '5rem';
  }
}

function variantToIconScale({ variant }) {
  switch (variant) {
    case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL:
    case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL_INVERTED:
    case BUTTON_VARIANTS.LEVEL_2_GREY_20_SMALL:
    case BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15_SMALL:
    case BUTTON_VARIANTS.LEVEL_1_GREEN_SMALL:
    case BUTTON_VARIANTS.LEVEL_1_RED_SMALL:
    case BUTTON_VARIANTS.LEVEL_1_FACEBOOK_BLUE_SMALL:
      return 'scale(0.7142857142)';
    case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90:
    case BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_INVERTED:
    case BUTTON_VARIANTS.LEVEL_2_GREY_20:
    case BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15:
    case BUTTON_VARIANTS.LEVEL_1_GREEN:
    case BUTTON_VARIANTS.LEVEL_1_RED:
    case BUTTON_VARIANTS.LEVEL_1_FACEBOOK_BLUE:
    case BUTTON_VARIANTS.LEVEL_3_GREY_100:
    default:
      return 'none';
  }
}

export const ButtonStyles = css`
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'equal', 2)};
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'equal', 2)};
  ${variantToFluidFontSize};
  width: 100%;
  height: ${variantToHeight};
  border: 0.2rem solid ${variantToBorderColor('normal')};
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.1']}rem;
  background-color: ${variantToBackgroundColor('normal')};
  color: ${variantToTextColor('normal')};
  text-align: center;
  text-decoration: none;
  transition: ${/* sc-value */ getThemeTransition('border-color', 'fast')},
    ${/* sc-value */ getThemeTransition('background-color', 'fast')};
  cursor: pointer;

  &:hover {
    border: 0.2rem solid ${variantToBorderColor('hover')};
    background-color: ${variantToBackgroundColor('hover')};
    color: ${variantToTextColor('hover')};
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border: 0.2rem solid ${variantToBorderColor('focus')};
    background-color: ${variantToBackgroundColor('focus')};
    color: ${variantToTextColor('focus')};
    text-decoration: none;
  }

  &:active {
    border: 0.2rem solid ${variantToBorderColor('active')};
    background-color: ${variantToBackgroundColor('active')};
    color: ${variantToTextColor('active')};
  }

  &:disabled {
    border: 0.1rem solid ${getThemeColor('bluegrey.80')};
    background-color: ${getThemeColor('bluegrey.95')};
    color: ${getThemeColor('bluegrey.70')};
    cursor: not-allowed;
  }

  svg {
    color: inherit;
    fill: currentColor;
    transform: ${variantToIconScale};
  }

  ${media.from400up`
    width: auto;
  `}
`;

export const ButtonContentWrapper = styled.span`
  display: grid;
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'equal', 5)};
  align-items: center;
  grid-auto-flow: column;
  pointer-events: none;
`;

export const StyledButton = styled.button`
  ${ButtonStyles};
`;

export const StyledLabel = styled.label`
  ${ButtonStyles};
  display: flex;
  align-items: center;
`;

export const StyledAnchor = styled.a`
  ${ButtonStyles};
  display: inline-flex;
  justify-content: center;

  ${({ isExternal }) =>
    isExternal &&
    css`
      &&&::after {
        content: '';
        display: inline-block;
        align-self: center;
        width: 0.7em;
        height: 0.7em;
        margin-left: 1rem;
        background-image: url('/static/img/arrow-external-white.svg');
        background-size: contain;
        background-repeat: no-repeat;
        transition: ${getThemeTransition('transform')};
      }

      &&&:hover::after,
      &&&:focus::after,
      &&&:active::after {
        transform: rotate(45deg);
      }
    `};
`;
