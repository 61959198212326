import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={20} height={18} viewBox="0 0 20 18" fill="#202226" {...props}>
      <path d="M10,0 C14.74,0 19,3.73 19,9.22 C19.59,9.53 20,10.14 20,10.84 L20,10.84 L20,13.14 C20,13.86 19.59,14.47 19,14.78 L19,14.78 L19,16 C19,17.1 18.1,18 17,18 L17,18 L9,18 L9,16 L17,16 L17,8.9 C17,5.03 13.87,1.9 10,1.9 C6.13,1.9 3,5.03 3,8.9 L3,8.9 L3,15 L2,15 C0.9,15 0,14.1 0,13 L0,13 L0,11 C0,10.26 0.4,9.62 1,9.28 C1,3.65 5.31,0 10,0 Z M7,9 C7.55228475,9 8,9.44771525 8,10 C8,10.5522847 7.55228475,11 7,11 C6.44771525,11 6,10.5522847 6,10 C6,9.44771525 6.44771525,9 7,9 Z M13,9 C13.5522847,9 14,9.44771525 14,10 C14,10.5522847 13.5522847,11 13,11 C12.4477153,11 12,10.5522847 12,10 C12,9.44771525 12.4477153,9 13,9 Z M10.05,3 C13.04,3 15.52,5.18 16,8.03 C12.88,8 10.19,6.19 8.88,3.56 C8.35,6.24 6.49,8.44 4.02,9.45 C3.76,5.51 7.02,3 10.05,3 Z" />
    </svg>
  );
}

export default SvgComponent;
