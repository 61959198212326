import { selectGetUID } from '@selectors/auth';

import request from '@utils/request';

import * as endpoints from '@common/endpoints';
import * as actions from '../actions/customerInfo';

export const fetchCustomerInfo = () => (dispatch, getState) => {
  const customerId = selectGetUID(getState());

  dispatch(actions.getCustomerInfoRequest());

  return request
    .get(endpoints.customerInfo(customerId))
    .then(({ data }) => {
      dispatch(actions.getCustomerInfoSuccess(data));
    })
    .catch(error => {
      dispatch(actions.getCustomerInfoFailure(error));
    });
};

export const fetchCustomerBadge = () => (dispatch, getState) => {
  const customerId = selectGetUID(getState());

  dispatch(actions.getCustomerBadgeRequest());

  return request
    .get(endpoints.customerBadge(customerId))
    .then(({ data }) => {
      dispatch(actions.getCustomerBadgeSuccess(data));
    })
    .catch(error => {
      dispatch(actions.getCustomerBadgeFailure(error));
    });
};
