export const GET_HOMEPAGE_REQUEST = 'GET_HOMEPAGE_REQUEST';
export const GET_HOMEPAGE_SUCCESS = 'GET_HOMEPAGE_SUCCESS';
export const GET_HOMEPAGE_FAILURE = 'GET_HOMEPAGE_FAILURE';

export const getHomepageRequest = () => ({
  type: GET_HOMEPAGE_REQUEST,
});

export const getHomepageSuccess = payload => ({
  payload,
  type: GET_HOMEPAGE_SUCCESS,
});

export const getHomepageFailure = payload => ({
  payload,
  type: GET_HOMEPAGE_FAILURE,
});
