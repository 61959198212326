import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={10} height={18} viewBox="0 0 10 18" fill="#202226" {...props}>
      <path d="M3.99629692,18 L2.99627449,18 L3.99629692,11 L0.496218418,11 C-0.0837945919,11 -0.0737943676,10.68 0.116209894,10.34 C0.306214156,10 0.166211016,10.26 0.186211464,10.22 C1.4762404,7.94 3.41628391,4.54 5.99634178,0 L6.99636422,0 L5.99634178,7 L9.49642029,7 C9.98643128,7 10.0564329,7.33 9.96643083,7.51 L9.89642926,7.66 C5.95634089,14.55 3.99629692,18 3.99629692,18 Z" />
    </svg>
  );
}

export default SvgComponent;
