export const ORDER_NEWEST = 'sorting_date';
export const ORDER_OLDEST = '-sorting_date';
export const ORDER_LOWEST_PRICE = '-sorting_price';
export const ORDER_HIGHEST_PRICE = 'sorting_price';

export const ORDERING_TYPES = [
  ORDER_NEWEST,
  ORDER_OLDEST,
  ORDER_LOWEST_PRICE,
  ORDER_HIGHEST_PRICE,
];

export const SIZES_SECTION = 'sizes';
export const COLORS_SECTION = 'colors';
export const SORT_SECTION = 'sort';

export const OFFER_TYPES = {
  OFFER: 'OFFER',
  SALE: 'SALE',
  VIPOFFER: 'VIPOFFER',
  MOMENT: 'MOMENTS',
};
