import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '../actions/customerDetail';

const initialState = {
  data: {
    personal: {
      address: {
        additionalAddress: '',
        city: '',
        country: '',
        houseNr: '',
        street: '',
        zipCode: '',
      },
      dateOfBirth: '',
      firstname: '',
      gender: null,
      language: '',
      lastname: '',
    },
    phoneData: {
      phone: '',
    },
  },
  isFetching: false,
};

export default function customerDetailReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...action.payload.customerDetail,
      };
    case actions.CLEAR_CUSTOMER_DETAILS:
      return { ...initialState };

    case actions.GET_CUSTOMER_DETAIL_REQUEST:
      return { ...state, isFetching: true };

    case actions.GET_CUSTOMER_DETAIL_SUCCESS:
    case actions.UPDATE_CUSTOMER_DETAIL_SUCCESS:
    case actions.CREATE_CUSTOMER_DETAIL_SUCCESS: {
      const newStateData = {
        ...state.data,
        ...action.payload,
      };

      if (typeof newStateData.personal?.gender === 'number') {
        newStateData.personal.gender = newStateData.personal.gender.toString();
      }

      return {
        ...state,
        data: {
          ...newStateData,
        },
        isFetching: false,
      };
    }

    default:
      return state;
  }
}
