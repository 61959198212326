import React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={20} height={14} viewBox="0 0 20 14" {...props}>
      <path d="M13 8.06l5.781 5.781c-.24.102-.504.158-.78.158H2c-.278 0-.542-.056-.782-.158L7 8.06l2.47 2.47a.747.747 0 001.06 0L13 8.06zM.16 1.218L5.94 7 .159 12.781A1.997 1.997 0 010 12V2c0-.277.057-.54.159-.78zm19.683 0c.102.24.158.504.158.782v10c0 .277-.056.54-.158.78L14.062 7zM18 0c.277 0 .541.056.781.158l-8.78 8.781L1.218.158C1.46.056 1.723 0 2 0z" />
    </svg>
  );
}

export default SvgComponent;
