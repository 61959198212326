/* eslint-disable sort-keys */
export const BUTTON_VARIANTS = {
  LEVEL_1_GREEN: 'LEVEL_1_GREEN',
  LEVEL_1_GREEN_SMALL: 'LEVEL_1_GREEN_SMALL',
  LEVEL_1_FACEBOOK_BLUE: 'LEVEL_1_FACEBOOK_BLUE',
  LEVEL_1_FACEBOOK_BLUE_SMALL: 'LEVEL_1_FACEBOOK_BLUE_SMALL',
  LEVEL_1_RED: 'LEVEL_1_RED',
  LEVEL_1_RED_SMALL: 'LEVEL_1_RED_SMALL',
  LEVEL_2_BLUEGREY_90: 'LEVEL_2_BLUEGREY_90',
  LEVEL_2_BLUEGREY_90_SMALL: 'LEVEL_2_BLUEGREY_90_SMALL',
  LEVEL_2_BLUEGREY_90_INVERTED: 'LEVEL_2_BLUEGREY_90_INVERTED',
  LEVEL_2_BLUEGREY_90_SMALL_INVERTED: 'LEVEL_2_BLUEGREY_90_SMALL_INVERTED',
  LEVEL_2_GREY_20: 'LEVEL_2_GREY_20',
  LEVEL_2_GREY_20_SMALL: 'LEVEL_2_GREY_20_SMALL',
  LEVEL_2_GREY_100_15: 'LEVEL_2_GREY_100_15',
  LEVEL_2_GREY_100_15_SMALL: 'LEVEL_2_GREY_100_15_SMALL',
  LEVEL_3_BLUEGREY_15: 'LEVEL_3_BLUEGREY_15',
  LEVEL_3_BLUEGREY_15_SMALL: 'LEVEL_3_BLUEGREY_15_SMALL',
  LEVEL_3_GREY_100: 'LEVEL_3_GREY_100',
  LEVEL_4_RED: 'LEVEL_4_RED',
  LEVEL_4_RED_SMALL: 'LEVEL_4_RED_SMALL',
  LEVEL_4_GREEN: 'LEVEL_4_GREEN',
  LEVEL_4_GREEN_SMALL: 'LEVEL_4_GREEN_SMALL',
  LEVEL_4_BLUEGREY_15: 'LEVEL_4_BLUEGREY_15',
  LEVEL_4_BLUEGREY_15_SMALL: 'LEVEL_4_BLUEGREY_15_SMALL',
};

export const variants = Object.values(BUTTON_VARIANTS);
