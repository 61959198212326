import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { isBrowser } from '@utils/environmentCheck';

import { BodyAttributesContext } from './bodyAttributesContext';

const ClientBodyAttributes = ({ children }) => {
  const attributes = useRef({});

  const setBodyAttribute = (attr, value) => {
    attributes.current[attr] = value;

    document.body.setAttribute(attr, value);
  };

  const removeBodyAttribute = attr => {
    delete attributes.current[attr];

    document.body.removeAttribute(attr);
  };

  return isBrowser ? (
    <BodyAttributesContext.Provider
      value={{
        attributes,
        setBodyAttribute,
        removeBodyAttribute,
      }}
    >
      {children}
    </BodyAttributesContext.Provider>
  ) : (
    children
  );
};

ClientBodyAttributes.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ClientBodyAttributes;
