import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={31} height={14} viewBox="0 0 29 13" {...props}>
      <path d="M6.5 9.7L10.1 0h2.8L8 13H5L0 0h3l3.5 9.7zm7.6 3.3h2.7V0h-2.7v13zm8-7h2c1.4 0 2.2-.8 2.2-2 0-1-.8-1.8-2.1-1.8H22v3.7zm2.4 2H22v5h-2.6V0h5C27.3 0 29 1.7 29 4c0 2.4-1.8 4.1-4.5 4.1z" />
    </svg>
  );
}

export default SvgComponent;
