import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { useHistoryLengthContext } from '@common/contexts/historyLengthContext';

import { ChevronIcon, Container, IconWrapper } from './BackChevronLink.styled';

const ICON_WIDTH = 12;
const ICON_HEIGHT = 21;

export const BackChevronLink = ({ href, as, className, onClick }) => {
  const router = useRouter();
  const { length: historyLength, previousUrl } = useHistoryLengthContext();
  const handleLinkClick = e => {
    e.stopPropagation();
    e.preventDefault();
    router.back();
  };

  const handleButtonLinkClick = e => {
    if (historyLength > 0) {
      e.stopPropagation();
      e.preventDefault();
      router.push(previousUrl);
    }
  };

  return (
    <Container>
      {href ? (
        <Link legacyBehavior passHref href={href} as={as}>
          <IconWrapper
            className={className}
            data-testid="back-chevron-link"
            onClick={handleLinkClick}
          >
            <ChevronIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
          </IconWrapper>
        </Link>
      ) : (
        <IconWrapper
          customAs="button"
          type="button"
          IconWrapper
          className={className}
          data-testid="back-chevron-link"
          onClick={e => {
            if (onClick) {
              onClick(e);
            }

            handleButtonLinkClick(e);
          }}
        >
          <ChevronIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
        </IconWrapper>
      )}
    </Container>
  );
};

BackChevronLink.defaultProps = {
  as: undefined,
  href: undefined,
  onClick: undefined,
  className: undefined,
};
BackChevronLink.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]),
  onClick: PropTypes.func,
};

export default BackChevronLink;
