export const variants = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const variantKeys = Object.values(variants);

export const alertKeys = {
  EMAIL_SENT_ALERT: 'EMAIL_SENT_ALERT',
};
