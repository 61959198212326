export const GET_DEFAULT_META_INFO_REQUEST = 'GET_DEFAULT_META_INFO_REQUEST';
export const GET_DEFAULT_META_INFO_SUCCESS = 'GET_DEFAULT_META_INFO_SUCCESS';
export const GET_DEFAULT_META_INFO_FAILURE = 'GET_DEFAULT_META_INFO_FAILURE';

export const getDefaultMetaInfoRequest = () => ({
  type: GET_DEFAULT_META_INFO_REQUEST,
});

export const getDefaultMetaInfoSuccess = payload => ({
  payload,
  type: GET_DEFAULT_META_INFO_SUCCESS,
});

export const getDefaultMetaInfoFailure = payload => ({
  payload,
  type: GET_DEFAULT_META_INFO_FAILURE,
});
