import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '@actions/activities';

const initialState = {
  all: [],
  hasFetchedAll: false,
  hasFetchedPurchases: false,
  isFetchingAll: false,
  isFetchingPurchases: false,
  purchases: [],
  transactionDetails: {},
};

export default function activitiesReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...action.payload.activities,
      };

    case actions.FETCH_ALL_ACTIVITIES_REQUEST:
      return {
        ...state,
        isFetchingAll: true,
      };

    case actions.FETCH_ALL_ACTIVITIES_SUCCESS:
      return {
        ...state,
        all: [...action.payload],
        hasFetchedAll: true,
        isFetchingAll: false,
      };

    case actions.FETCH_ALL_ACTIVITIES_FAILURE:
      return {
        ...state,
        isFetchingAll: false,
      };

    case actions.FETCH_PURCHASE_ACTIVITIES_REQUEST:
      return {
        ...state,
        isFetchingPurchases: true,
      };

    case actions.FETCH_PURCHASE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        hasFetchedPurchases: true,
        isFetchingPurchases: false,
        purchases: [...action.payload],
      };

    case actions.FETCH_PURCHASE_ACTIVITIES_FAILURE:
      return {
        ...state,
        isFetchingPurchases: false,
      };

    case actions.FETCH_TRANSACTION_DETAILS_REQUEST:
      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          [action.id]: {
            ...(state.transactionDetails[action.id] || {}),
            isFetching: true,
          },
        },
      };

    case actions.FETCH_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          [action.id]: {
            ...(state.transactionDetails[action.id] || {}),
            data: action.payload,
            isFetching: false,
          },
        },
      };

    case actions.FETCH_TRANSACTION_DETAILS_FAILURE:
      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          [action.id]: {
            ...(state.transactionDetails[action.id] || {}),
            data: null,
            isFetching: false,
          },
        },
      };

    default:
      return state;
  }
}
