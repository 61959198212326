import React from 'react';

import AttributesSet from './AttributesSet';
import { BodyAttributesContext } from './bodyAttributesContext';

export default class ServerBodyAttributes {
  constructor() {
    this.instance = new AttributesSet();
  }

  collectAttributes(children) {
    return (
      <BodyAttributesContext.Provider value={this.instance}>
        {children}
      </BodyAttributesContext.Provider>
    );
  }

  getAttributes() {
    return this.instance.getBodyAttributes();
  }
}
