import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M24 9.938c0-.211-.106-.317-.319-.317l-2.548-.317v.106a8.952 8.952 0 00-.956-2.326l1.487-2.01a.32.32 0 000-.422l-2.549-2.537a.324.324 0 00-.425 0L16.673 3.7l.106.106c-.744-.423-1.593-.74-2.337-.951L14.124.317c0-.106-.106-.317-.319-.317h-3.61c-.213 0-.319.106-.319.317l-.318 2.538a9.036 9.036 0 00-2.337.951l-2.017-1.48a.324.324 0 00-.425 0L2.23 4.863a.32.32 0 000 .423l1.593 2.01a8.952 8.952 0 00-.956 2.325L.32 9.938c-.107 0-.319.106-.319.318v3.594c0 .212.106.317.319.317l2.548.318c.213.845.531 1.585.956 2.326l-1.487 2.008a.32.32 0 000 .423l2.549 2.538a.324.324 0 00.425 0l2.017-1.586c.744.423 1.487.74 2.337.951l.318 2.538c0 .106.106.317.319.317h3.61c.213 0 .32-.106.32-.317l.318-2.538h-.107a9.036 9.036 0 002.337-.951l2.017 1.48a.324.324 0 00.425 0l2.549-2.537a.32.32 0 000-.423l-1.593-2.01c.425-.74.743-1.48.956-2.325l2.548-.317c.107 0 .319-.106.319-.318V9.938zm-11.894 8.247c-3.504 0-6.265-2.855-6.265-6.238 0-3.489 2.867-6.238 6.265-6.238 3.505 0 6.266 2.855 6.266 6.238.106 3.383-2.761 6.238-6.266 6.238z"
      />
    </svg>
  );
}

export default SvgComponent;
