import { HYDRATE } from 'next-redux-wrapper';
import merge from 'lodash/merge';
import { normalize, schema } from 'normalizr';
import keyBy from 'lodash/keyBy';

import * as actions from '../actions/shop';

const initialState = {
  count: 0,
  entities: {
    availability: {},
    customerShops: [],
    stores: {},
    groupedAlphabetically: {},
  },
  result: [],
  isFetchingShops: true,
  isFetchingCustomerShops: true,
};

const availabilitySchema = productColorId =>
  new schema.Entity(
    'availability',
    {},
    {
      idAttribute: 'storeId',
      processStrategy: entity => ({ [productColorId]: entity }),
    },
  );

const storeSchema = new schema.Entity('stores', {}, { idAttribute: 'id' });

const LETTER_REGEXP = /^[a-z]$/i;

export default function shopReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...action.payload.shop,
        entities: merge({}, state.entities, action.payload.shop.entities),
        isFetchingShops: state.isFetchingShops,
        isFetchingCustomerShops: state.isFetchingCustomerShops,
      };
    }

    case actions.GET_CUSTOMER_SHOPS_REQUEST:
      return {
        ...state,
        isFetchingCustomerShops: true,
      };

    case actions.GET_CUSTOMER_SHOPS_SUCCESS:
      return merge({}, state, {
        entities: { customerShops: action.ids.map(({ erpStoreId: id }) => id) },
        isFetchingCustomerShops: false,
      });

    case actions.GET_CUSTOMER_SHOPS_FAILURE:
      return {
        ...state,
        isFetchingCustomerShops: false,
      };

    case actions.GET_PRODUCT_AVAILABILITY_SUCCESS:
      return merge(
        {},
        state,
        normalize(action.payload.availability, [
          availabilitySchema(action.payload.productColorId),
        ]),
      );

    case actions.GET_SHOPS_REQUEST:
      return {
        ...state,
        isFetchingShops: true,
      };

    case actions.GET_SHOPS_SUCCESS: {
      const stores = keyBy(action.payload, 'id');
      const shopsGroupedSpecialChars = [];
      const shopsGroupedAlphabetically = action.payload.reduce((acc, store) => {
        const firstChar = Array.from(store.city)[0]; // unicode

        if (!LETTER_REGEXP.test(firstChar)) {
          shopsGroupedSpecialChars.push(store.id);

          return acc;
        }

        return {
          ...acc,
          [firstChar]: [...(acc[firstChar] || []), store],
        };
      }, {});

      const shopsGroupedSorted = Object.fromEntries(
        Object.entries(
          Object.entries(shopsGroupedAlphabetically).reduce(
            (acc, [letter, shopsInGroup]) => {
              const shopsSorted = shopsInGroup
                .sort(
                  (storeA, storeB) =>
                    storeA.city.localeCompare(storeB.city) ||
                    storeA.street.localeCompare(storeB.street) ||
                    +storeA.houseNr - +storeB.houseNr,
                )
                .map(store => store.id);

              return {
                ...acc,
                [letter]: shopsSorted,
              };
            },
            {},
          ),
        ).sort(),
      );

      return merge(
        {},
        state,
        {
          entities: {
            stores,
            groupedAlphabetically: {
              ...shopsGroupedSorted,
              ...(shopsGroupedSpecialChars.length && {
                '#': shopsGroupedSpecialChars,
              }),
            },
          },
        },
        { isFetchingShops: false },
      );
    }

    case actions.GET_SHOPS_FAILURE:
      return {
        ...state,
        isFetchingShops: false,
      };

    case actions.GET_SHOP_SUCCESS:
      return merge({}, state, normalize(action.shop, storeSchema));

    default:
      return state;
  }
}
