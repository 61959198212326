import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '../actions/jobs';

const initialState = {
  areasOfOperationList: [],
  isFetchingAreasList: false,
  isFetchingJobOffers: false,
  jobOffers: {},
};

export const JobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...action.payload.jobs,
        jobOffers: {
          ...state.jobOffers,
          ...action.payload.jobs.jobOffers,
        },
      };
    }

    case actions.GET_AREAS_OF_OPERATION_REQUEST: {
      return {
        ...state,
        isFetchingAreasList: true,
      };
    }

    case actions.GET_AREAS_OF_OPERATION_SUCCESS: {
      return {
        ...state,
        areasOfOperationList: action.payload,
        isFetchingAreasList: false,
      };
    }

    case actions.GET_AREAS_OF_OPERATION_FAILURE: {
      return {
        ...state,
        isFetchingAreasList: false,
      };
    }

    case actions.GET_JOB_OFFERS_LIST_REQUEST: {
      return {
        ...state,
        isFetchingJobOffers: true,
      };
    }

    case actions.GET_JOB_OFFERS_LIST_SUCCESS: {
      return {
        ...state,
        isFetchingJobOffers: false,
        jobOffers: {
          ...state.jobOffers,
          [action.payload.areaOfOperationId]: action.payload.data,
        },
      };
    }

    case actions.GET_JOB_OFFERS_LIST_FAILURE: {
      return {
        ...state,
        isFetchingJobOffers: false,
      };
    }

    default:
      return state;
  }
};

export default JobsReducer;
