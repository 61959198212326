import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={9} height={9} viewBox="0 0 9 9" {...props}>
      <path d="M8.1 0L4.5 3.6.9 0 0 .9l3.6 3.6L0 8.1l.9.9 3.6-3.6L8.1 9l.9-.9-3.6-3.6L9 .9z" />
    </svg>
  );
}

export default SvgComponent;
