export const GET_MOBILE_PLAN_REQUEST = 'GET_MOBILE_PLAN_REQUEST';
export const GET_MOBILE_PLAN_SUCCESS = 'GET_MOBILE_PLAN_SUCCESS';
export const GET_MOBILE_PLAN_FAILURE = 'GET_MOBILE_PLAN_FAILURE';

export const getMobilePlanRequest = () => ({ type: GET_MOBILE_PLAN_REQUEST });

export const getMobilePlanSuccess = data => ({
  payload: {
    data,
  },
  type: GET_MOBILE_PLAN_SUCCESS,
});

export const getMobilePlanFailure = error => ({
  payload: {
    error,
  },
  type: GET_MOBILE_PLAN_FAILURE,
});
