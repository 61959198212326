import React, { Fragment } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { getPathUrl } from '@utils/urls';

import { PageWrapper } from '@common/containers/Layout/Layout.styled';
import { LOGO_CHICOREE_GREEN } from '@common/images';

import { Container, Content, Title, Wrapper, Logo } from './Error500.styled';

const Error500 = () => {
  const { t } = useTranslation();
  const { asPath } = useRouter();
  const ogUrl = getPathUrl(asPath);

  const title = t('errorPage.metaTitle', 'Website zurzeit nicht erreichbar');
  const metaTitle = t('general.metaTitle', '{{title}} | Chicorée', { title });
  const metaDescription = t('errorPage.metaDescription', '');

  return (
    <Fragment>
      <Head>
        <title>{metaTitle}</title>
        {metaDescription && (
          <meta content={metaDescription} name="description" />
        )}
        <meta content="/static/img/open-graph-image.jpg" property="og:image" />
        <meta content="1200" property="og:image:width" />
        <meta content="630" property="og:image:height" />
        <meta content={ogUrl} property="og:url" />
      </Head>
      <PageWrapper>
        <Wrapper>
          <Container withoutStickyBar>
            <Logo src={LOGO_CHICOREE_GREEN} />
            <Title>
              {t('errorPage.heading', 'Website zurzeit nicht erreichbar')}
            </Title>
            <Content>
              {t(
                'errorPage.content',
                'Bitte entschuldige die Unannehmlichkeiten, aber unsere Website hat gerade ein technisches Problem. Versuche es doch in ein paar Minuten erneut und wir bedanken uns für Deine Geduld.',
              )}
            </Content>
          </Container>
        </Wrapper>
      </PageWrapper>
    </Fragment>
  );
};

export default Error500;
