import styled from 'styled-components';

import Typography from '@common/components/Typography';
import RouteLayout from '@common/containers/RouteLayout';
import { ContentWrapper } from '@common/containers/Layout/Layout.styled';

export const Container = styled(RouteLayout)`
  margin-top: 0;
`;

export const Title = styled(Typography).attrs({
  customAs: 'h1',
  variant: 'h2',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 2)};
`;

export const Content = styled(Typography).attrs({
  variant: 'paragraph1',
})``;

export const Wrapper = styled(ContentWrapper)`
  display: flex;
  place-items: center;

  ${({ theme }) => theme.getFluidAttribute('padding-left', 0, 24)};
  ${({ theme }) => theme.getFluidAttribute('padding-right', 0, 24)};
`;

export const Logo = styled.img`
  width: auto;
  height: 5rem;
`;
