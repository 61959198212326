import { v4 as uuidv4 } from 'uuid';

/*
  Discount
 */
export const GET_GIFT_CARDS_DISCOUNT_REQUEST =
  'GET_GIFT_CARDS_DISCOUNT_REQUEST';
export const GET_GIFT_CARDS_DISCOUNT_SUCCESS =
  'GET_GIFT_CARDS_DISCOUNT_SUCCESS';
export const GET_GIFT_CARDS_DISCOUNT_FAILURE =
  'GET_GIFT_CARDS_DISCOUNT_FAILURE';

export const getGiftCardsDiscountRequest = () => ({
  type: GET_GIFT_CARDS_DISCOUNT_REQUEST,
});

export const getGiftCardsDiscountSuccess = payload => ({
  payload,
  type: GET_GIFT_CARDS_DISCOUNT_SUCCESS,
});

export const getGiftCardsDiscountFailure = payload => ({
  payload,
  type: GET_GIFT_CARDS_DISCOUNT_FAILURE,
});

/*
  Card Values
 */
export const GET_GIFT_CARD_VALUES_REQUEST = 'GET_GIFT_CARD_VALUES_REQUEST';
export const GET_GIFT_CARD_VALUES_SUCCESS = 'GET_GIFT_CARD_VALUES_SUCCESS';
export const GET_GIFT_CARD_VALUES_FAILURE = 'GET_GIFT_CARD_VALUES_FAILURE';

export const getGiftCardValuesRequest = () => ({
  type: GET_GIFT_CARD_VALUES_REQUEST,
});

export const getGiftCardValuesSuccess = payload => ({
  payload,
  type: GET_GIFT_CARD_VALUES_SUCCESS,
});

export const getGiftCardValuesFailure = payload => ({
  payload,
  type: GET_GIFT_CARD_VALUES_FAILURE,
});

/*
  Order
 */
export const ADD_GIFT_CARD = 'ADD_GIFT_CARD_TO_ORDER';

export const addGiftCard = value => ({
  payload: {
    id: uuidv4(),
    value,
  },
  type: ADD_GIFT_CARD,
});

export const REMOVE_GIFT_CARD = 'REMOVE_GIFT_CARD_FROM_ORDER';

export const removeGiftCard = id => ({
  payload: {
    id,
  },
  type: REMOVE_GIFT_CARD,
});

export const UPDATE_GIFT_CARD_PERSONALIZATION =
  'UPDATE_GIFT_CARD_PERSONALIZATION_IN_ORDER';

export const updateGiftCardPersonalization = ({ id, personalization }) => ({
  payload: {
    id,
    personalization,
  },
  type: UPDATE_GIFT_CARD_PERSONALIZATION,
});

export const SET_GIFT_CARDS_ORDER_ADDRESS = 'SET_GIFT_CARDS_ORDER_ADDRESS';

export const setGiftCardsOrderAddress = payload => ({
  payload,
  type: SET_GIFT_CARDS_ORDER_ADDRESS,
});

export const SET_GIFT_CARDS_ORDER_DIRECT_DELIVERY =
  'SET_GIFT_CARDS_ORDER_DIRECT_DELIVERY';

export const setGiftCardsOrderDirectDelivery = directDelivery => ({
  payload: directDelivery,
  type: SET_GIFT_CARDS_ORDER_DIRECT_DELIVERY,
});

/*
  Payment
 */
export const SUBMIT_GIFT_CARDS_ORDER_REQUEST =
  'SUBMIT_GIFT_CARDS_ORDER_REQUEST';
export const SUBMIT_GIFT_CARDS_ORDER_SUCCESS =
  'SUBMIT_GIFT_CARDS_ORDER_SUCCESS';
export const SUBMIT_GIFT_CARDS_ORDER_FAILURE =
  'SUBMIT_GIFT_CARDS_ORDER_FAILURE';

export const submitGiftCardsOrderRequest = () => ({
  type: SUBMIT_GIFT_CARDS_ORDER_REQUEST,
});

export const submitGiftCardsOrderSuccess = payload => ({
  payload,
  type: SUBMIT_GIFT_CARDS_ORDER_SUCCESS,
});

export const submitGiftCardsOrderFailure = payload => ({
  payload,
  type: SUBMIT_GIFT_CARDS_ORDER_FAILURE,
});

export const CLEAR_GIFT_CARDS_ORDER = 'CLEAR_GIFT_CARDS_ORDER';

export const clearGiftCardsOrder = () => ({
  type: CLEAR_GIFT_CARDS_ORDER,
});

export const CLEAR_GIFT_CARDS_PAYMENT = 'CLEAR_GIFT_CARDS_PAYMENT';

export const clearGiftCardsPayment = () => ({
  type: CLEAR_GIFT_CARDS_PAYMENT,
});
