export const GET_CUSTOMER_MOBILE_SUBSCRIPTION_REQUEST =
  'GET_MOBILE_SUBSCRIPTION_REQUEST';
export const GET_CUSTOMER_MOBILE_SUBSCRIPTION_SUCCESS =
  'GET_MOBILE_SUBSCRIPTION_SUCCESS';
export const GET_CUSTOMER_MOBILE_SUBSCRIPTION_FAILURE =
  'GET_MOBILE_SUBSCRIPTION_FAILURE';

export const getCustomerMobileSubscriptionRequest = () => ({
  type: GET_CUSTOMER_MOBILE_SUBSCRIPTION_REQUEST,
});

export const getCustomerMobileSubscriptionSuccess = payload => ({
  payload,
  type: GET_CUSTOMER_MOBILE_SUBSCRIPTION_SUCCESS,
});

export const getCustomerMobileSubscriptionFailure = payload => ({
  payload,
  type: GET_CUSTOMER_MOBILE_SUBSCRIPTION_FAILURE,
});
