import React, { useEffect } from 'react';
import throttle from 'lodash/throttle';

import { isBrowser } from '@utils/environmentCheck';

export const useScroll = () => {
  const prevScrollY = React.useRef(isBrowser ? window.pageYOffset : 0);
  const [scrollDirection, setScrollDirection] = React.useState(null);
  const handleScroll = throttle(() => {
    const pageYOffset = window?.pageYOffset ?? 0;
    const scrolledUp = prevScrollY.current > pageYOffset;
    const isBelowThreshold = pageYOffset <= 60;

    setScrollDirection(!(scrolledUp || isBelowThreshold) ? 'down' : 'up');
    prevScrollY.current = pageYOffset;
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return {
    scrollDirection,
  };
};
