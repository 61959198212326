export const CLEAR_CUSTOMER_DETAILS = 'CLEAR_CUSTOMER_DETAILS';

export const GET_CUSTOMER_DETAIL_REQUEST = 'GET_CUSTOMER_DETAIL_REQUEST';
export const GET_CUSTOMER_DETAIL_SUCCESS = 'GET_CUSTOMER_DETAIL_SUCCESS';
export const GET_CUSTOMER_DETAIL_FAILURE = 'GET_CUSTOMER_DETAIL_FAILURE';

export const UPDATE_CUSTOMER_DETAIL_REQUEST = 'UPDATE_CUSTOMER_DETAIL_REQUEST';
export const UPDATE_CUSTOMER_DETAIL_SUCCESS = 'UPDATE_CUSTOMER_DETAIL_SUCCESS';
export const UPDATE_CUSTOMER_DETAIL_FAILURE = 'UPDATE_CUSTOMER_DETAIL_FAILURE';

export const CREATE_CUSTOMER_DETAIL_REQUEST = 'CREATE_CUSTOMER_DETAIL_REQUEST';
export const CREATE_CUSTOMER_DETAIL_SUCCESS = 'CREATE_CUSTOMER_DETAIL_SUCCESS';
export const CREATE_CUSTOMER_DETAIL_FAILURE = 'CREATE_CUSTOMER_DETAIL_FAILURE';

export const clearCustomerDetails = () => ({
  type: CLEAR_CUSTOMER_DETAILS,
});

export const getCustomerDetailRequest = () => ({
  type: GET_CUSTOMER_DETAIL_REQUEST,
});

export const getCustomerDetailSuccess = payload => ({
  payload,
  type: GET_CUSTOMER_DETAIL_SUCCESS,
});

export const getCustomerDetailFailure = payload => ({
  payload,
  type: GET_CUSTOMER_DETAIL_FAILURE,
});

export const updateCustomerDetailRequest = () => ({
  type: UPDATE_CUSTOMER_DETAIL_REQUEST,
});

export const updateCustomerDetailSuccess = payload => ({
  payload,
  type: UPDATE_CUSTOMER_DETAIL_SUCCESS,
});

export const updateCustomerDetailFailure = payload => ({
  payload,
  type: UPDATE_CUSTOMER_DETAIL_FAILURE,
});

export const createCustomerDetailRequest = () => ({
  type: CREATE_CUSTOMER_DETAIL_REQUEST,
});

export const createCustomerDetailSuccess = payload => ({
  payload,
  type: CREATE_CUSTOMER_DETAIL_SUCCESS,
});

export const createCustomerDetailFailure = payload => ({
  payload,
  type: CREATE_CUSTOMER_DETAIL_FAILURE,
});
