import React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={10}
      height={10}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.15079364,-3.55271368e-15 L9.98268397,0.831890331 L5.822,4.991 L9.98268397,9.15079364 L9.15079364,9.98268397 L4.991,5.822 L0.831890331,9.98268397 L3.94684285e-14,9.15079364 L4.159,4.991 L3.80806497e-14,0.831890331 L0.831890331,-1.94289029e-15 L4.991,4.159 L9.15079364,-3.55271368e-15 Z" />
    </svg>
  );
}

export default SvgComponent;
