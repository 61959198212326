import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={16} height={21} viewBox="0 0 16 21" {...props}>
      <path d="m9.3566 9.8824 3.6088 3.6916 1.8038 1.8458-1.8038 1.8458-3.6088 3.7345-1.8462-1.8454 2.6853-2.7476h-10.196v-2.1463h9.986l-2.4755-2.5326 1.8462-1.8458zm-2.6713-9.8824 1.8042 1.8458-2.4755 2.5326h9.986v2.1467h-10.153l2.6429 2.7468-1.8042 1.8458-3.6503-3.7345-1.8042-1.8462 1.8042-1.8454 3.6503-3.6916z" />
    </svg>
  );
}

export default SvgComponent;
