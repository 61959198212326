import { HYDRATE } from 'next-redux-wrapper';

import {
  GET_GIFT_CARD_VALUES_REQUEST,
  GET_GIFT_CARD_VALUES_SUCCESS,
  GET_GIFT_CARD_VALUES_FAILURE,
} from '@actions/giftCards';

const initialState = {
  data: [],
  isFetching: false,
};

export default function giftCardValuesReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case HYDRATE:
      return {
        ...payload.giftCards.cardValues,
      };

    case GET_GIFT_CARD_VALUES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case GET_GIFT_CARD_VALUES_SUCCESS: {
      return {
        ...state,
        data: [...payload],
        isFetching: false,
      };
    }

    case GET_GIFT_CARD_VALUES_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }

    default:
      return state;
  }
}
