export const GET_BARCODE_REQUEST = 'GET_BARCODE_REQUEST';
export const GET_BARCODE_SUCCESS = 'GET_BARCODE_SUCCESS';
export const GET_BARCODE_FAILURE = 'GET_BARCODE_FAILURE';

export const getBarcodeRequest = () => ({
  type: GET_BARCODE_REQUEST,
});

export const getBarcodeSuccess = payload => ({
  payload,
  type: GET_BARCODE_SUCCESS,
});

export const getBarcodeFailure = payload => ({
  payload,
  type: GET_BARCODE_FAILURE,
});
