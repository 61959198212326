import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={19} height={14} viewBox="0 0 19 14" fill="#202226" {...props}>
      <path d="M14.6818182,12.6875 C13.965,12.6875 13.3863636,12.10125 13.3863636,11.375 C13.3863636,10.64875 13.965,10.0625 14.6818182,10.0625 C15.3986364,10.0625 15.9772727,10.64875 15.9772727,11.375 C15.9772727,12.10125 15.3986364,12.6875 14.6818182,12.6875 L14.6818182,12.6875 Z M15.9772727,4.8125 L17.67,7 L13.8181818,7 L13.8181818,4.8125 L15.9772727,4.8125 Z M4.31818182,12.6875 C3.60136364,12.6875 3.02272727,12.10125 3.02272727,11.375 C3.02272727,10.64875 3.60136364,10.0625 4.31818182,10.0625 C5.035,10.0625 5.61363636,10.64875 5.61363636,11.375 C5.61363636,12.10125 5.035,12.6875 4.31818182,12.6875 L4.31818182,12.6875 Z M16.4090909,3.5 L13.8181818,3.5 L13.8181818,0 L1.72727273,0 C0.777272727,0 0,0.7875 0,1.75 L0,11.375 L1.72727273,11.375 C1.72727273,12.8275 2.88454545,14 4.31818182,14 C5.75181818,14 6.90909091,12.8275 6.90909091,11.375 L12.0909091,11.375 C12.0909091,12.8275 13.2481818,14 14.6818182,14 C16.1154545,14 17.2727273,12.8275 17.2727273,11.375 L19,11.375 L19,7 L16.4090909,3.5 Z" />
    </svg>
  );
}

export default SvgComponent;
