import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={12} height={16} viewBox="0 0 12 16" {...props}>
      <path d="M0.857142857,14.2222222 C0.857142857,15.2 1.62857143,16 2.57142857,16 L9.42857143,16 C10.3714286,16 11.1428571,15.2 11.1428571,14.2222222 L11.1428571,3.55555556 L0.857142857,3.55555556 L0.857142857,14.2222222 Z M12,0.888888889 L9,0.888888889 L8.14285714,0 L3.85714286,0 L3,0.888888889 L0,0.888888889 L0,2.66666667 L12,2.66666667 L12,0.888888889 Z" />
    </svg>
  );
}

export default SvgComponent;
