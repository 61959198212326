import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={18} height={15} viewBox="0 0 18 15" fill="#202226" {...props}>
      <path d="M17,0 L1,0 L1,1.875 L17,1.875 L17,0 Z M18,9.375 L18,7.5 L17,2.8125 L1,2.8125 L0,7.5 L0,9.375 L1,9.375 L1,15 L11,15 L11,9.375 L15,9.375 L15,15 L17,15 L17,9.375 L18,9.375 Z M9,13.125 L3,13.125 L3,9.375 L9,9.375 L9,13.125 Z" />
    </svg>
  );
}

export default SvgComponent;
