const UrlPattern = require('url-pattern');

const { LANGUAGE_DE, LANGUAGE_FR } = require('@common/constants/languages');
const {
  frenchUrlsLinkGenerationRouteMap,
  germanUrlsLinkGenerationRouteMap,
} = require('@common/routes');

const generateUrlPatterns = routesMap =>
  Object.entries(routesMap).reduce(
    (accum, [pathname, url]) => ({
      ...accum,
      [pathname]: new UrlPattern(
        typeof url === 'string' || typeof url.pattern === 'string'
          ? `${url.pattern ?? url}(/)(?)`
          : url.pattern,
      ),
    }),
    {},
  );

const germanUrlPatterns = generateUrlPatterns(germanUrlsLinkGenerationRouteMap);
const frenchUrlPatterns = generateUrlPatterns(frenchUrlsLinkGenerationRouteMap);

function getUrlPatternsByLanguage(language) {
  switch (language) {
    case LANGUAGE_FR:
      return frenchUrlPatterns;

    case LANGUAGE_DE:
    default:
      return germanUrlPatterns;
  }
}

function findRouteByPath(path, lang) {
  const patterns = getUrlPatternsByLanguage(lang);
  const [pathname, pattern] =
    Object.entries(patterns).find(([, urlPattern]) => urlPattern.match(path)) ||
    [];

  return {
    pathname,
    pattern,
  };
}

module.exports = {
  findRouteByPath,
};
