import getConfig from 'next/config';

import * as actions from '@actions/categories';

import request from '@utils/request';

import * as endpoints from '@common/endpoints';

const { PRODUCTS_BACKEND_URL } = getConfig().publicRuntimeConfig;

export const fetchCategories = () => dispatch => {
  dispatch(actions.fetchCategoriesRequest());

  return request
    .get(endpoints.categories(), {
      baseURL: PRODUCTS_BACKEND_URL,
    })
    .then(({ data }) => {
      dispatch(actions.fetchCategoriesSuccess(data));
    })
    .catch(error => {
      dispatch(actions.fetchCategoriesFailure(error));
    });
};

export const fetchCategoryIcons = () => dispatch => {
  dispatch(actions.getCategoryIconsRequest());

  return request
    .get(endpoints.categoryIcons())
    .then(({ data }) => {
      dispatch(actions.getCategoryIconsSuccess(data));
    })
    .catch(error => {
      dispatch(actions.getCategoryIconsFailure(error));
    });
};
