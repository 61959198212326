const PREFIX = '[AUTH]';

export const LOGIN_REQUEST = `${PREFIX} LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${PREFIX} LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${PREFIX} LOGIN_FAILURE`;

export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = payload => ({
  payload,
  type: LOGIN_SUCCESS,
});

export const loginFailure = () => ({
  type: LOGIN_FAILURE,
});

export const REGISTER_REQUEST = `${PREFIX} REGISTER_REQUEST`;
export const REGISTER_SUCCESS = `${PREFIX} REGISTER_SUCCESS`;
export const REGISTER_FAILURE = `${PREFIX} REGISTER_FAILURE`;

export const registerRequest = () => ({
  type: REGISTER_REQUEST,
});

export const registerSuccess = () => ({
  type: REGISTER_SUCCESS,
});

export const registerFailure = () => ({
  type: REGISTER_FAILURE,
});

export const GET_USER_DATA_REQUEST = `${PREFIX} GET_USER_DATA_REQUEST`;
export const GET_USER_DATA_SUCCESS = `${PREFIX} GET_USER_DATA_SUCCESS`;
export const GET_USER_DATA_FAILURE = `${PREFIX} GET_USER_DATA_FAILURE`;

export const getUserDataRequest = () => ({
  type: GET_USER_DATA_REQUEST,
});

export const getUserDataSuccess = payload => ({
  payload,
  type: GET_USER_DATA_SUCCESS,
});

export const getUserDataFailure = () => ({
  type: GET_USER_DATA_FAILURE,
});

export const LOGOUT_USER = `${PREFIX} LOGOUT_USER`;

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const REGISTER_WITH_CARD_REQUEST = `${PREFIX} REGISTER_WITH_CARD_REQUEST`;
export const REGISTER_WITH_CARD_SUCCESS = `${PREFIX} REGISTER_WITH_CARD_SUCCESS`;
export const REGISTER_WITH_CARD_FAILURE = `${PREFIX} REGISTER_WITH_CARD_FAILURE`;

export const registerWithCardRequest = () => ({
  type: REGISTER_WITH_CARD_REQUEST,
});

export const registerWithCardSuccess = () => ({
  type: REGISTER_WITH_CARD_SUCCESS,
});

export const registerWithCardFailure = () => ({
  type: REGISTER_WITH_CARD_FAILURE,
});
