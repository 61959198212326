import { clearCustomerDetails } from '@actions/customerDetail';
import { clearCustomerInfo } from '@actions/customerInfo';
import { clearFavoritesAuthenticated } from '@actions/favorites';
import { clearGoodies } from '@actions/goodies';
import { clearProfilePicture } from '@actions/profileImage';
import { clearVouchers } from '@actions/vouchers';
import { clearNotifications } from '@actions/notifications';
import {
  getUserDataFailure,
  getUserDataRequest,
  getUserDataSuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
  registerFailure,
  registerRequest,
  registerSuccess,
  registerWithCardFailure,
  registerWithCardRequest,
  registerWithCardSuccess,
  logoutUser,
} from '@actions/auth';
import { clearGiftCardsOrder, clearGiftCardsPayment } from '@actions/giftCards';
import { clearBasket, clearBasketOrder } from '@actions/basket';

import { setAnalyticsUserId } from '@utils/analytics';
import { isBrowser } from '@utils/environmentCheck';
import { storeAccessToken } from '@utils/jwt';
import request from '@utils/request';
import localApiRequest from '@utils/localApiRequest';

import * as endpoints from '@common/endpoints';

export const getUserData = () => dispatch => {
  dispatch(getUserDataRequest());

  return request
    .get(endpoints.externalUID())
    .then(({ data }) => {
      if (isBrowser) {
        const uid = data.customerId;

        setAnalyticsUserId(uid);
      }
      dispatch(getUserDataSuccess(data));

      return data;
    })
    .catch(error => {
      dispatch(getUserDataFailure());

      throw error;
    });
};

export const login = payload => dispatch => {
  dispatch(loginRequest());

  return request
    .post(endpoints.login(), payload, {})
    .then(async ({ data }) => {
      dispatch(loginSuccess());

      await storeAccessToken(data);

      request.setAuthorizationToken(data.token);

      return data;
    })
    .catch(error => {
      dispatch(loginFailure());

      throw error;
    });
};

export const register = payload => dispatch => {
  dispatch(registerRequest());

  return localApiRequest
    .post(endpoints.localSignUp(), payload)
    .then(({ data }) => {
      dispatch(registerSuccess());

      return data;
    })
    .catch(error => {
      dispatch(registerFailure());

      throw error;
    });
};

export const registerWithCard = payload => dispatch => {
  dispatch(registerWithCardRequest());

  return request
    .post(endpoints.registerWithCard(), payload)
    .then(({ data }) => {
      dispatch(registerWithCardSuccess());

      return data;
    })
    .catch(error => {
      dispatch(registerWithCardFailure());

      throw error;
    });
};

export const logout = () => dispatch => {
  dispatch(clearFavoritesAuthenticated());
  dispatch(clearCustomerDetails());
  dispatch(clearCustomerInfo());
  dispatch(clearGoodies());
  dispatch(clearProfilePicture());
  dispatch(clearVouchers());
  dispatch(clearNotifications());
  dispatch(clearGiftCardsOrder());
  dispatch(clearGiftCardsPayment());
  dispatch(clearBasket());
  dispatch(clearBasketOrder());
  dispatch(logoutUser());

  return request.removeAuthorizationToken();
};
