import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={22} height={20} viewBox="0 0 22 20" {...props}>
      <path d="M16 0a6 6 0 016 6c0 3.8-3.1 7-8 11.3l-1.5 1.3L11 20l-1.5-1.4L8 17.3C3 13 0 9.8 0 6a6 6 0 016-6c2 0 3.9.9 5 2.3C12.3.9 14 0 16 0zm0 2c-1.3 0-2.4.5-3.3 1.4l-.2.2-1.6 1.7-1.4-1.8C8.7 2.6 7.4 2 6 2a4 4 0 00-4 4c0 2.6 1.9 5 7.7 10.1l1.1 1 .2.2.2-.2.8-.7c6-5.3 8-7.8 8-10.4a4 4 0 00-4-4z" />
    </svg>
  );
}

export default SvgComponent;
