import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '../actions/homepage';

const initialState = {
  data: null,
  isFetching: false,
};

export default function homepageReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...payload.homepage,
      };

    case actions.GET_HOMEPAGE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actions.GET_HOMEPAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...payload,
        },
      };

    case actions.GET_HOMEPAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
