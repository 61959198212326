import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '../actions/notifications';

const initialState = {
  allowNewsletter: {
    email: false,
    push: false,
    sms: false,
  },
  allowPersonalizedAds: false,
  allowPostalDelivery: false,
  isFetchingReminders: false,
  outdatedCommunicationSettings: [],
  outdatedReminders: [],
  reminders: [],
  disallowGlobalSettings: {
    email: false,
    push: false,
    sms: false,
  },
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.notifications,
      };
    case actions.CLEAR_NOTIFICATIONS:
      return { ...initialState };
    case actions.PERSONALIZED_ADS_SUCCESS:
      return {
        ...state,
        allowPersonalizedAds: action.payload,
      };
    case actions.POSTAL_DELIVERY_AGREEMENT_SUCCESS:
      return {
        ...state,
        allowPostalDelivery: action.payload,
      };
    case actions.NEWSLETTER_EMAIL_AGREEMENT_SUCCESS:
      return {
        ...state,
        allowNewsletter: {
          ...state.allowNewsletter,
          email: action.payload,
        },
      };
    case actions.NEWSLETTER_SMS_AGREEMENT_SUCCESS:
      return {
        ...state,
        allowNewsletter: {
          ...state.allowNewsletter,
          sms: action.payload,
        },
      };
    case actions.NEWSLETTER_PUSH_AGREEMENT_SUCCESS:
      return {
        ...state,
        allowNewsletter: {
          ...state.allowNewsletter,
          push: action.payload,
        },
      };
    case actions.NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isFetchingReminders: true,
      };
    case actions.NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isFetchingReminders: false,
        reminders: action.payload,
      };
    case actions.NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isFetchingReminders: false,
      };
    case actions.GLOBAL_NOTIFICATIONS_SETTINGS_REQUEST:
      return {
        ...state,
        isFetchingReminders: true,
      };
    case actions.GLOBAL_NOTIFICATIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetchingReminders: false,
        disallowGlobalSettings: action.payload,
      };
    case actions.GLOBAL_NOTIFICATIONS_SETTINGS_FAILURE:
      return {
        ...state,
        isFetchingReminders: false,
      };
    case actions.ALL_SMS_NOTIFICATIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetchingReminders: false,
        disallowGlobalSettings: {
          ...state.disallowGlobalSettings,
          sms: action.payload,
        },
      };
    case actions.ALL_EMAIL_NOTIFICATIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetchingReminders: false,
        disallowGlobalSettings: {
          ...state.disallowGlobalSettings,
          email: action.payload,
        },
      };
    case actions.ALL_PUSH_NOTIFICATIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetchingReminders: false,
        disallowGlobalSettings: {
          ...state.disallowGlobalSettings,
          push: action.payload,
        },
      };
    case actions.UPDATE_REMINDER_AGREEMENT_SUCCESS:
      return {
        ...state,
        reminders: [
          ...state.reminders.map(reminder =>
            reminder.settings.id === action.payload.reminderId
              ? {
                  ...reminder,
                  settings: {
                    ...reminder.settings,
                    [action.payload.platform]: action.payload.isAllowed,
                  },
                }
              : reminder,
          ),
        ],
      };
    case actions.UPDATE_PERSONALIZED_ADS_AGREEMENT_SUCCESS:
      return {
        ...state,
        allowPersonalizedAds: action.payload,
      };
    case actions.UPDATE_POSTAL_DELIVERY_AGREEMENT_SUCCESS:
      return {
        ...state,
        allowPostalDelivery: action.payload,
      };
    case actions.UPDATE_NEWSLETTER_EMAIL_AGREEMENT_SUCCESS:
      return {
        ...state,
        allowNewsletter: {
          ...state.allowNewsletter,
          email: action.payload,
        },
      };
    case actions.UPDATE_NEWSLETTER_PUSH_AGREEMENT_SUCCESS:
      return {
        ...state,
        allowNewsletter: {
          ...state.allowNewsletter,
          push: action.payload,
        },
      };
    case actions.UPDATE_NEWSLETTER_SMS_AGREEMENT_SUCCESS:
      return {
        ...state,
        allowNewsletter: {
          ...state.allowNewsletter,
          sms: action.payload,
        },
      };
    case actions.GET_SETTINGS_NEED_UPDATE_SUCCESS:
      return {
        ...state,
        outdatedCommunicationSettings: action.payload.communicationSettings,
        outdatedReminders: action.payload.notificationSettings,
      };
    case actions.MUTE_SETTINGS_ALERT_SUCCESS:
      return {
        ...state,
        outdatedCommunicationSettings: [],
        outdatedReminders: [],
      };
    default:
      return state;
  }
}
