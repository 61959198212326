import {
  COMPANY_MENU_IDENTIFIER,
  FOR_CLIENTS_MENU_IDENTIFIER,
  LEGAL_MENU_IDENTIFIER,
  MAIN_MENU_IDENTIFIER,
} from '@common/constants/menus';

export const selectMenus = state => state.menus;

export const selectLegalMenu = state => state.menus[LEGAL_MENU_IDENTIFIER];
export const selectCompanyMenu = state => state.menus[COMPANY_MENU_IDENTIFIER];
export const selectMainMenu = state => state.menus[MAIN_MENU_IDENTIFIER];
export const selectMainMenuItems = state =>
  state.menus[MAIN_MENU_IDENTIFIER]?.menuItems || [];
export const selectForClientsMenu = state =>
  state.menus[FOR_CLIENTS_MENU_IDENTIFIER];
