import React from 'react';
import PropTypes from 'prop-types';

import { useGlobalOverlayContext } from '@common/contexts/globalOverlayContext';

import { LayoutOverlayStyled } from './LayoutOverlay.styled';

function LayoutOverlay({ disableContent }) {
  const { layoutOverlay } = useGlobalOverlayContext();

  return layoutOverlay.isActive ? (
    <LayoutOverlayStyled>
      {!disableContent && layoutOverlay.innerContent}
    </LayoutOverlayStyled>
  ) : null;
}

LayoutOverlay.propTypes = {
  disableContent: PropTypes.bool,
};

LayoutOverlay.defaultProps = {
  disableContent: false,
};

export default LayoutOverlay;
