import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { useUserAgent } from '@common/contexts/userAgentContext';
import { StickyBarVisibilityProvider } from '@common/contexts/stickyBarVisibilityContext';
import useStickyBarVisibility from '@common/hooks/useStickyBarVisibility';
import GridSectionContent from '@common/components/GridSectionContent';
import AlertMessages from '@common/components/AlertMessages/AlertMessages';

import StickyBar from './StickyBar';
import { Section, Title } from './RouteLayout.styled';

export const RouteLayout = ({
  children,
  backRoute,
  backRouteAs,
  handleBackButtonClick,
  title,
  titleAs,
  titleVariant,
  className,
  stickyTitle,
  stickyTitleColumns,
  columns,
  isMobileHero,
  isHero,
  isProduct,
  alwaysVisible,
  additionalControls,
  contentSpacingTop,
  isTransparent,
  withoutStickyBar,
  expandedStickyColumns,
  isHeroAlwaysExpanded,
  forceStickyOnMobileApp,
}) => {
  const alertRef = React.useRef(null);
  const { isMobileApp } = useUserAgent();
  const isStickyTitleVisible = useStickyBarVisibility();
  const [alertHeight, setAlertHeight] = React.useState(0);

  React.useEffect(() => {
    if (alertRef.current) {
      setAlertHeight(alertRef.current.offsetHeight);
    }
  }, [alertRef.current]);

  const router = useRouter();

  const {
    route,
    query: { friendlyError, friendlySuccess, ...query },
    asPath,
    replace: routerReplace,
  } = router;

  const onAlertClose = async alertType => {
    const asPathUrl = new URL(`${global?.location?.origin}${asPath}`);
    const pathParams = new URLSearchParams(asPathUrl.search);

    pathParams.delete(alertType);
    const newAsPath = `${asPathUrl.pathname}${
      pathParams.toString() ? `?${pathParams.toString()}` : ''
    }`;

    if (alertType) {
      query[alertType] = undefined;
    }

    setAlertHeight(0);

    await routerReplace(
      {
        pathname: route,
        query,
      },
      newAsPath,
      {
        shallow: true,
      },
    );
  };

  const hasAlertMessages = Boolean(friendlyError || friendlySuccess);

  return (
    <StickyBarVisibilityProvider value={isStickyTitleVisible}>
      {!withoutStickyBar && (
        <StickyBar
          alwaysVisible={alwaysVisible}
          backRoute={backRoute}
          backRouteAs={backRouteAs}
          handleBackButtonClick={handleBackButtonClick}
          columns={stickyTitleColumns || columns}
          expandedStickyColumns={expandedStickyColumns}
          additionalControls={additionalControls}
          stickyTitle={stickyTitle}
          isMobileHero={isMobileHero}
          isHero={isHero}
          isProduct={isProduct}
          isTransparent={isTransparent}
          isHeroAlwaysExpanded={isHeroAlwaysExpanded}
          isAlertMessagesVisible={hasAlertMessages}
          alertHeight={alertHeight}
          forceOnMobileApp={forceStickyOnMobileApp}
        >
          {hasAlertMessages && (
            <AlertMessages
              errorMessage={friendlyError}
              successMessage={friendlySuccess}
              onAlertClose={onAlertClose}
              ref={alertRef}
            />
          )}
        </StickyBar>
      )}
      <Section
        alertHeight={alertHeight}
        hasContent={backRoute || title || handleBackButtonClick}
        className={className}
        isMobileApp={isMobileApp}
        withoutStickyBar={withoutStickyBar}
      >
        <GridSectionContent columns={columns} spacingTop={contentSpacingTop}>
          {title && (
            <Title variant={titleVariant} customAs={titleAs}>
              {title}
            </Title>
          )}
          {children}
        </GridSectionContent>
      </Section>
    </StickyBarVisibilityProvider>
  );
};

RouteLayout.defaultProps = {
  additionalControls: null,
  backRoute: undefined,
  backRouteAs: undefined,
  className: undefined,
  columns: 12,
  isHero: false,
  isMobileHero: false,
  stickyTitle: undefined,
  stickyTitleColumns: undefined,
  expandedStickyColumns: undefined,
  title: undefined,
  titleAs: 'h1',
  titleVariant: 'h3',
  handleBackButtonClick: undefined,
  isProduct: false,
  alwaysVisible: false,
  contentSpacingTop: null,
  isTransparent: false,
  withoutStickyBar: false,
  isHeroAlwaysExpanded: false,
  forceStickyOnMobileApp: false,
};

RouteLayout.propTypes = {
  additionalControls: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  backRoute: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]),
  backRouteAs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  columns: PropTypes.oneOf([12, 10, 8, 6, 4, 2]),
  isHero: PropTypes.bool,
  isMobileHero: PropTypes.bool,
  stickyTitle: PropTypes.string,
  stickyTitleColumns: PropTypes.number,
  expandedStickyColumns: PropTypes.number,
  title: PropTypes.string,
  titleAs: PropTypes.elementType,
  titleVariant: PropTypes.string,
  handleBackButtonClick: PropTypes.func,
  isProduct: PropTypes.bool,
  isTransparent: PropTypes.bool,
  alwaysVisible: PropTypes.bool,
  contentSpacingTop: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  withoutStickyBar: PropTypes.bool,
  isHeroAlwaysExpanded: PropTypes.bool,
  forceStickyOnMobileApp: PropTypes.bool,
};

export default RouteLayout;
