import * as actions from '@actions/barcode';
import { selectGetUID } from '@selectors/auth';

import request from '@utils/request';

import * as endpoints from '@common/endpoints';

export const fetchBarcode = () => (dispatch, getState) => {
  const customerId = selectGetUID(getState());

  dispatch(actions.getBarcodeRequest());

  return request
    .get(endpoints.barcode(customerId))
    .then(({ data }) => {
      dispatch(actions.getBarcodeSuccess(data));
    })
    .catch(error => {
      dispatch(actions.getBarcodeFailure(error));
    });
};
