import request from '@utils/request';

import { defaultMetaInfo } from '@common/endpoints';
import {
  getDefaultMetaInfoRequest,
  getDefaultMetaInfoSuccess,
  getDefaultMetaInfoFailure,
} from '../actions/defaultMetaInfo';

export const fetchDefaultMetaInfo = () => dispatch => {
  dispatch(getDefaultMetaInfoRequest());

  return request
    .get(defaultMetaInfo())
    .then(({ data }) => {
      dispatch(getDefaultMetaInfoSuccess(data));
    })
    .catch(error => {
      dispatch(getDefaultMetaInfoFailure(error));
    });
};
