import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={25} height={20} viewBox="0 0 25 20" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M1.494 14.103v4.359h4.233V20H1.345c-.67 0-1.27-.578-1.339-1.256L0 18.615v-4.512h1.494zm23.506 0v4.512c0 .69-.56 1.308-1.22 1.378l-.125.007h-4.382v-1.538h4.232l.001-4.36H25zM5.13 4.256V16.36H3.734V4.256H5.13zm2.59 0V16.36H6.324V4.256h1.394zm3.286 0V16.36h-2.39V4.256h2.39zm3.088 0V16.36H12.6V4.256h1.494zm4.68 0V16.36h-3.286V4.256h3.287zm1.993 0V16.36h-1.195V4.256h1.195zM5.727 0v1.538H1.494v4.36H0V1.384C0 .695.56.077 1.22.007L1.345 0h4.382zm17.928 0c.67 0 1.27.578 1.339 1.256l.006.129v4.512h-1.494l-.001-4.359h-4.232V0h4.382z"
      />
    </svg>
  );
}

export default SvgComponent;
