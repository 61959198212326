import React from 'react';

export function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8,0 C12.416,0 16,3.584 16,8 C16,12.416 12.416,16 8,16 C3.584,16 0,12.416 0,8 C0,3.584 3.584,0 8,0 Z M8.8,7.2 L7.2,7.2 L7.2,12 L8.8,12 L8.8,7.2 Z M8.8,4 L7.2,4 L7.2,5.6 L8.8,5.6 L8.8,4 Z" />
    </svg>
  );
}

export default SvgComponent;
