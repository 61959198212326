import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={38} height={32} viewBox="0 0 38 32" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M0 13.9V32h10.026V13.9c0-.5-.4-.9-.902-.9H1.003C.4 13 0 13.4 0 13.9zM14.037 1.6V32h10.026V1.6c0-.9-.4-1.6-.902-1.6H15.04c-.602 0-1.003.7-1.003 1.6zm13.937 5.6V32H38V7.2c0-.7-.401-1.3-.902-1.3h-8.222c-.501 0-.902.6-.902 1.3z"
      />
    </svg>
  );
}

export default SvgComponent;
