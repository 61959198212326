export const PERSONALIZED_ADS_REQUEST = 'PERSONALIZED_ADS_REQUEST';
export const PERSONALIZED_ADS_SUCCESS = 'PERSONALIZED_ADS_SUCCESS';
export const PERSONALIZED_ADS_FAILURE = 'PERSONALIZED_ADS_FAILURE';

export const personalizedAdsRequest = () => ({
  type: PERSONALIZED_ADS_REQUEST,
});

export const personalizedAdsSuccess = payload => ({
  payload,
  type: PERSONALIZED_ADS_SUCCESS,
});

export const personalizedAdsFailure = payload => ({
  payload,
  type: PERSONALIZED_ADS_FAILURE,
});

export const POSTAL_DELIVERY_AGREEMENT_REQUEST =
  'POSTAL_DELIVERY_AGREEMENT_REQUEST';
export const POSTAL_DELIVERY_AGREEMENT_SUCCESS =
  'POSTAL_DELIVERY_AGREEMENT_SUCCESS';
export const POSTAL_DELIVERY_AGREEMENT_FAILURE =
  'POSTAL_DELIVERY_AGREEMENT_FAILURE';

export const postalDeliveryAgreementRequest = () => ({
  type: POSTAL_DELIVERY_AGREEMENT_REQUEST,
});

export const postalDeliveryAgreementSuccess = payload => ({
  payload,
  type: POSTAL_DELIVERY_AGREEMENT_SUCCESS,
});

export const postalDeliveryAgreementFailure = payload => ({
  payload,
  type: POSTAL_DELIVERY_AGREEMENT_FAILURE,
});

export const NEWSLETTER_EMAIL_AGREEMENT_REQUEST =
  'NEWSLETTER_EMAIL_AGREEMENT_REQUEST';
export const NEWSLETTER_EMAIL_AGREEMENT_SUCCESS =
  'NEWSLETTER_EMAIL_AGREEMENT_SUCCESS';
export const NEWSLETTER_EMAIL_AGREEMENT_FAILURE =
  'NEWSLETTER_EMAIL_AGREEMENT_FAILURE';

export const newsletterEmailAgreementRequest = () => ({
  type: NEWSLETTER_EMAIL_AGREEMENT_REQUEST,
});

export const newsletterEmailAgreementSuccess = payload => ({
  payload,
  type: NEWSLETTER_EMAIL_AGREEMENT_SUCCESS,
});

export const newsletterEmailAgreementFailure = payload => ({
  payload,
  type: NEWSLETTER_EMAIL_AGREEMENT_FAILURE,
});

export const NEWSLETTER_SMS_AGREEMENT_REQUEST =
  'NEWSLETTER_SMS_PUSH_AGREEMENT_REQUEST';
export const NEWSLETTER_SMS_AGREEMENT_SUCCESS =
  'NEWSLETTER_SMS_PUSH_AGREEMENT_SUCCESS';
export const NEWSLETTER_SMS_AGREEMENT_FAILURE =
  'NEWSLETTER_SMS_PUSH_AGREEMENT_FAILURE';

export const newsletterSmsAgreementRequest = () => ({
  type: NEWSLETTER_SMS_AGREEMENT_REQUEST,
});

export const newsletterSmsAgreementSuccess = payload => ({
  payload,
  type: NEWSLETTER_SMS_AGREEMENT_SUCCESS,
});

export const newsletterSmsAgreementFailure = payload => ({
  payload,
  type: NEWSLETTER_SMS_AGREEMENT_FAILURE,
});

export const NEWSLETTER_PUSH_AGREEMENT_REQUEST =
  'NEWSLETTER_PUSH_AGREEMENT_REQUEST';
export const NEWSLETTER_PUSH_AGREEMENT_SUCCESS =
  'NEWSLETTER_PUSH_AGREEMENT_SUCCESS';
export const NEWSLETTER_PUSH_AGREEMENT_FAILURE =
  'NEWSLETTER_PUSH_AGREEMENT_FAILURE';

export const newsletterPushAgreementRequest = () => ({
  type: NEWSLETTER_PUSH_AGREEMENT_REQUEST,
});

export const newsletterPushAgreementSuccess = payload => ({
  payload,
  type: NEWSLETTER_PUSH_AGREEMENT_SUCCESS,
});

export const newsletterPushAgreementFailure = payload => ({
  payload,
  type: NEWSLETTER_PUSH_AGREEMENT_FAILURE,
});

export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE';

export const notificationsRequest = () => ({
  type: NOTIFICATIONS_REQUEST,
});

export const notificationsSuccess = payload => ({
  payload,
  type: NOTIFICATIONS_SUCCESS,
});

export const notificationsFailure = payload => ({
  payload,
  type: NOTIFICATIONS_FAILURE,
});

export const GLOBAL_NOTIFICATIONS_SETTINGS_REQUEST =
  'GLOBAL_NOTIFICATIONS_SETTINGS_REQUEST';
export const GLOBAL_NOTIFICATIONS_SETTINGS_SUCCESS =
  'GLOBAL_NOTIFICATIONS_SETTINGS_SUCCESS';
export const GLOBAL_NOTIFICATIONS_SETTINGS_FAILURE =
  'GLOBAL_NOTIFICATIONS_SETTINGS_FAILURE';

export const globalNotificationsSettingsRequest = () => ({
  type: GLOBAL_NOTIFICATIONS_SETTINGS_REQUEST,
});

export const globalNotificationsSettingsSuccess = payload => ({
  payload,
  type: GLOBAL_NOTIFICATIONS_SETTINGS_SUCCESS,
});

export const globalNotificationsSettingsFailure = payload => ({
  payload,
  type: GLOBAL_NOTIFICATIONS_SETTINGS_FAILURE,
});

export const ALL_SMS_NOTIFICATIONS_SETTINGS_SUCCESS =
  'ALL_SMS_NOTIFICATIONS_SETTINGS_SUCCESS';
export const ALL_EMAIL_NOTIFICATIONS_SETTINGS_SUCCESS =
  'ALL_EMAIL_NOTIFICATIONS_SETTINGS_SUCCESS';
export const ALL_PUSH_NOTIFICATIONS_SETTINGS_SUCCESS =
  'ALL_PUSH_NOTIFICATIONS_SETTINGS_SUCCESS';

export const allSmsNotificationsSettingsSuccess = payload => ({
  payload,
  type: ALL_SMS_NOTIFICATIONS_SETTINGS_SUCCESS,
});

export const allEmailNotificationsSettingsSuccess = payload => ({
  payload,
  type: ALL_EMAIL_NOTIFICATIONS_SETTINGS_SUCCESS,
});

export const allPushNotificationsSettingsSuccess = payload => ({
  payload,
  type: ALL_PUSH_NOTIFICATIONS_SETTINGS_SUCCESS,
});

export const UPDATE_REMINDER_AGREEMENT_REQUEST =
  'UPDATE_REMINDER_AGREEMENT_REQUEST';
export const UPDATE_REMINDER_AGREEMENT_SUCCESS =
  'UPDATE_REMINDER_AGREEMENT_SUCCESS';
export const UPDATE_REMINDER_AGREEMENT_FAILURE =
  'UPDATE_REMINDER_AGREEMENT_FAILURE';

export const updateReminderRequest = () => ({
  type: UPDATE_REMINDER_AGREEMENT_REQUEST,
});

export const updateReminderSuccess = payload => ({
  payload,
  type: UPDATE_REMINDER_AGREEMENT_SUCCESS,
});

export const updateReminderFailure = payload => ({
  payload,
  type: UPDATE_REMINDER_AGREEMENT_FAILURE,
});

export const UPDATE_PERSONALIZED_ADS_AGREEMENT_REQUEST =
  'UPDATE_PERSONALIZED_ADS_AGREEMENT_REQUEST';
export const UPDATE_PERSONALIZED_ADS_AGREEMENT_SUCCESS =
  'UPDATE_PERSONALIZED_ADS_AGREEMENT_SUCCESS';
export const UPDATE_PERSONALIZED_ADS_AGREEMENT_FAILURE =
  'UPDATE_PERSONALIZED_ADS_AGREEMENT_FAILURE';

export const updatePersonalizedAdsAgreementRequest = () => ({
  type: UPDATE_PERSONALIZED_ADS_AGREEMENT_REQUEST,
});

export const updatePersonalizedAdsAgreementSuccess = payload => ({
  payload,
  type: UPDATE_PERSONALIZED_ADS_AGREEMENT_SUCCESS,
});

export const updatePersonalizedAdsAgreementFailure = payload => ({
  payload,
  type: UPDATE_PERSONALIZED_ADS_AGREEMENT_FAILURE,
});

export const UPDATE_POSTAL_DELIVERY_AGREEMENT_REQUEST =
  'UPDATE_POSTAL_DELIVERY_AGREEMENT_REQUEST';
export const UPDATE_POSTAL_DELIVERY_AGREEMENT_SUCCESS =
  'UPDATE_POSTAL_DELIVERY_AGREEMENT_SUCCESS';
export const UPDATE_POSTAL_DELIVERY_AGREEMENT_FAILURE =
  'UPDATE_POSTAL_DELIVERY_AGREEMENT_FAILURE';

export const updatePostalDeliveryAgreementRequest = () => ({
  type: UPDATE_POSTAL_DELIVERY_AGREEMENT_REQUEST,
});

export const updatePostalDeliveryAgreementSuccess = payload => ({
  payload,
  type: UPDATE_POSTAL_DELIVERY_AGREEMENT_SUCCESS,
});

export const updatePostalDeliveryAgreementFailure = payload => ({
  payload,
  type: UPDATE_POSTAL_DELIVERY_AGREEMENT_FAILURE,
});

export const UPDATE_NEWSLETTER_EMAIL_AGREEMENT_REQUEST =
  'UPDATE_NEWSLETTER_EMAIL_AGREEMENT_REQUEST';
export const UPDATE_NEWSLETTER_EMAIL_AGREEMENT_SUCCESS =
  'UPDATE_NEWSLETTER_EMAIL_AGREEMENT_SUCCESS';
export const UPDATE_NEWSLETTER_EMAIL_AGREEMENT_FAILURE =
  'UPDATE_NEWSLETTER_EMAIL_AGREEMENT_FAILURE';

export const updateNewsletterEmailAgreementRequest = () => ({
  type: UPDATE_NEWSLETTER_EMAIL_AGREEMENT_REQUEST,
});

export const updateNewsletterEmailAgreementSuccess = payload => ({
  payload,
  type: UPDATE_NEWSLETTER_EMAIL_AGREEMENT_SUCCESS,
});

export const updateNewsletterEmailAgreementFailure = payload => ({
  payload,
  type: UPDATE_NEWSLETTER_EMAIL_AGREEMENT_FAILURE,
});

export const UPDATE_NEWSLETTER_PUSH_AGREEMENT_REQUEST =
  'UPDATE_NEWSLETTER_PUSH_AGREEMENT_REQUEST';
export const UPDATE_NEWSLETTER_PUSH_AGREEMENT_SUCCESS =
  'UPDATE_NEWSLETTER_PUSH_AGREEMENT_SUCCESS';
export const UPDATE_NEWSLETTER_PUSH_AGREEMENT_FAILURE =
  'UPDATE_NEWSLETTER_PUSH_AGREEMENT_FAILURE';

export const updateNewsletterPushAgreementRequest = () => ({
  type: UPDATE_NEWSLETTER_PUSH_AGREEMENT_REQUEST,
});

export const updateNewsletterPushAgreementSuccess = payload => ({
  payload,
  type: UPDATE_NEWSLETTER_PUSH_AGREEMENT_SUCCESS,
});

export const updateNewsletterPushAgreementFailure = payload => ({
  payload,
  type: UPDATE_NEWSLETTER_PUSH_AGREEMENT_FAILURE,
});

export const UPDATE_NEWSLETTER_SMS_AGREEMENT_REQUEST =
  'UPDATE_NEWSLETTER_SMS_AGREEMENT_REQUEST';
export const UPDATE_NEWSLETTER_SMS_AGREEMENT_SUCCESS =
  'UPDATE_NEWSLETTER_SMS_AGREEMENT_SUCCESS';
export const UPDATE_NEWSLETTER_SMS_AGREEMENT_FAILURE =
  'UPDATE_NEWSLETTER_SMS_AGREEMENT_FAILURE';

export const updateNewsletterSmsAgreementRequest = () => ({
  type: UPDATE_NEWSLETTER_SMS_AGREEMENT_REQUEST,
});

export const updateNewsletterSmsAgreementSuccess = payload => ({
  payload,
  type: UPDATE_NEWSLETTER_SMS_AGREEMENT_SUCCESS,
});

export const updateNewsletterSmsAgreementFailure = payload => ({
  payload,
  type: UPDATE_NEWSLETTER_SMS_AGREEMENT_FAILURE,
});

export const GET_SETTINGS_NEED_UPDATE_REQUEST =
  'GET_SETTINGS_NEED_UPDATE_REQUEST';
export const GET_SETTINGS_NEED_UPDATE_SUCCESS =
  'GET_SETTINGS_NEED_UPDATE_SUCCESS';
export const GET_SETTINGS_NEED_UPDATE_FAILURE =
  'GET_SETTINGS_NEED_UPDATE_FAILURE';

export const getSettingsNeedUpdateRequest = payload => ({
  payload,
  type: GET_SETTINGS_NEED_UPDATE_REQUEST,
});

export const getSettingsNeedUpdateSuccess = payload => ({
  payload,
  type: GET_SETTINGS_NEED_UPDATE_SUCCESS,
});

export const getSettingsNeedUpdateFailure = payload => ({
  payload,
  type: GET_SETTINGS_NEED_UPDATE_FAILURE,
});

export const MUTE_SETTINGS_ALERT_REQUEST = 'MUTE_SETTINGS_ALERT_REQUEST';
export const MUTE_SETTINGS_ALERT_SUCCESS = 'MUTE_SETTINGS_ALERT_SUCCESS';
export const MUTE_SETTINGS_ALERT_FAILURE = 'MUTE_SETTINGS_ALERT_FAILURE';

export const muteSettingsAlertRequest = payload => ({
  payload,
  type: MUTE_SETTINGS_ALERT_REQUEST,
});

export const muteSettingsAlertSuccess = payload => ({
  payload,
  type: MUTE_SETTINGS_ALERT_SUCCESS,
});

export const muteSettingsAlertFailure = payload => ({
  payload,
  type: MUTE_SETTINGS_ALERT_FAILURE,
});

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
});
