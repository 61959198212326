import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={18} height={22} viewBox="0 0 18 22" {...props}>
      <path d="M16 0c1.1 0 2.1.9 2 2v18a2 2 0 01-2 2H2a2 2 0 01-2-2V2C0 .9.9 0 2 0zm-.6 2H2.6c-.3 0-.6.3-.6.6v16.8c0 .3.3.6.6.6h12.8c.3 0 .6-.3.6-.6V2.6c0-.3-.3-.6-.6-.6zM11 14v2H4v-2h7zm3-4v2H4v-2h10zm0-4v2H4V6h10z" />
    </svg>
  );
}

export default SvgComponent;
