import * as actions from '@actions/menus';

import request from '@utils/request';

import {
  COMPANY_MENU_IDENTIFIER,
  FOR_CLIENTS_MENU_IDENTIFIER,
  LEGAL_MENU_IDENTIFIER,
  MAIN_MENU_IDENTIFIER,
} from '@common/constants/menus';
import * as endpoints from '@common/endpoints';

export const fetchMenu = identifier => dispatch => {
  dispatch(actions.getMenuRequest(identifier));

  return request
    .get(endpoints.menuDetails(identifier))
    .then(({ data }) => {
      dispatch(actions.getMenuSuccess(identifier, data));
    })
    .catch(error => {
      dispatch(actions.getMenuFailure(identifier, error));
    });
};

export const fetchAllMenus = () => dispatch =>
  Promise.all([
    dispatch(fetchMenu(LEGAL_MENU_IDENTIFIER)),
    dispatch(fetchMenu(FOR_CLIENTS_MENU_IDENTIFIER)),
    dispatch(fetchMenu(COMPANY_MENU_IDENTIFIER)),
    dispatch(fetchMenu(MAIN_MENU_IDENTIFIER)),
  ]);
