import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getConfig from 'next/config';

import { selectGetUID } from '@selectors/auth';

import { setMasterSlug } from '@utils/masterSlug';
import {
  setAnalyticsUserId,
  gtmInit,
  webAppDataLayerName,
} from '@utils/analytics';
import { isChicoreeApp } from '@utils/environmentCheck';

const { WEB_APP_GTM_ID, ENABLE_ANALYTICS } = getConfig().publicRuntimeConfig;

export const useAnalytics = (router, pageUrl) => {
  const userId = useSelector(selectGetUID);
  const [shouldUpdateMasterSlug, setShouldUpdateMasterSlug] = useState(false);

  useEffect(() => {
    if (userId) {
      setAnalyticsUserId(userId);
    }

    if (router.query?.ga_uid) {
      setAnalyticsUserId(router.query.ga_uid);
    }

    if (ENABLE_ANALYTICS && WEB_APP_GTM_ID && !isChicoreeApp()) {
      gtmInit(WEB_APP_GTM_ID, webAppDataLayerName);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shouldUpdateMasterSlug) {
      setMasterSlug(pageUrl);
      setShouldUpdateMasterSlug(false);
    }
  }, [pageUrl, shouldUpdateMasterSlug]);

  useEffect(() => {
    const updateMasterSlug = () => {
      setTimeout(() => setShouldUpdateMasterSlug(true), 200);
    };

    router.events.on('routeChangeComplete', updateMasterSlug);
    window.addEventListener('load', updateMasterSlug);

    return () => {
      router.events.off('routeChangeComplete', updateMasterSlug);
      window.removeEventListener('load', updateMasterSlug);
    };
  }, [router.events, pageUrl]);
};
