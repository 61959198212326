import * as actions from '@actions/shopEvent';

import request from '@utils/request';

import {
  shopEventStatus,
  shopEventProductDetails,
  shopEventProducts,
  productDescription,
} from '@common/endpoints';

export const fetchShopEventStatus = () => dispatch => {
  dispatch(actions.getShopEventStatusRequest());

  return request
    .get(shopEventStatus())
    .then(({ data }) => {
      dispatch(actions.getShopEventStatusSuccess(data));
    })
    .catch(() => {
      dispatch(actions.getShopEventStatusFailure());
    });
};

export const fetchShopEventProducts = () => dispatch => {
  dispatch(actions.getShopEventProductsRequest());

  return request
    .get(shopEventProducts())
    .then(({ data }) => {
      dispatch(actions.getShopEventProductsSuccess(data));
    })
    .catch(error => {
      dispatch(actions.getShopEventProductsFailure());

      throw error;
    });
};

export const fetchShopEventProductDetails = productId => dispatch => {
  dispatch(actions.getShopEventProductDetailsRequest());

  return request
    .get(shopEventProductDetails(productId))
    .then(({ data }) => {
      dispatch(actions.getShopEventProductDetailsSuccess(data));
    })
    .catch(error => {
      dispatch(actions.getShopEventProductDetailsFailure());

      throw error;
    });
};

export const fetchShopEventProductDescription = body => dispatch => {
  dispatch(actions.getShopEventProductDescriptionRequest());

  return request
    .post(productDescription(), body)
    .then(({ data }) => {
      dispatch(
        actions.getShopEventProductDescriptionSuccess({
          id: body.productId,
          ...data,
        }),
      );
    })
    .catch(() => {
      dispatch(actions.getShopEventProductDescriptionFailure());
    });
};
