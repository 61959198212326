import PropTypes from 'prop-types';
import React from 'react';

export const TiktokIcon = ({ alt, accessibilityId, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="28"
    viewBox="0 0 24 28"
    aria-labelledby={accessibilityId}
    {...props}
  >
    <title id={accessibilityId}>{alt}</title>
    <path d="M23.9960044,11.3282948 C23.7668467,11.350536 23.5367656,11.3621678 23.3064999,11.3632069 C20.7809298,11.3635589 18.4254066,10.0926448 17.0419318,7.98318684 L17.0419318,19.493048 C17.0419318,24.191297 13.2269676,28 8.5209743,28 C3.81496422,28 0,24.191297 0,19.493048 C0,14.794799 3.81496422,10.986096 8.5209743,10.986096 C8.69884435,10.986096 8.87271883,11.0020687 9.04758381,11.0130301 L9.04758381,15.2051459 C8.87271883,15.1841953 8.70084213,15.1522834 8.5209743,15.1522834 C6.11911585,15.1522834 4.17204737,17.0961477 4.17204737,19.4940536 C4.17204737,21.8919428 6.11911585,23.8358071 8.5209743,23.8358071 C10.9232692,23.8358071 13.0447662,21.9462635 13.0447662,19.5479218 L13.0867365,0 L17.1038967,0 C17.4826869,3.59646497 20.3874165,6.4056229 24,6.6692821 L24,11.3282948" />
  </svg>
);

TiktokIcon.propTypes = {
  alt: PropTypes.string.isRequired,
  accessibilityId: PropTypes.string.isRequired,
};
