import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '../actions/wheelOfFortune';

const initialState = {
  config: {
    data: {},
    isFetching: false,
  },
  wofToken: null,
};

export default function wheelOfFortuneReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.wheelOfFortune,
      };

    case actions.GET_WHEEL_OF_FORTUNE_CONFIG_REQUEST:
      return {
        ...state,
        config: {
          ...state.config,
          isFetching: true,
        },
      };

    case actions.GET_WHEEL_OF_FORTUNE_CONFIG_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          data: action.payload,
          isFetching: false,
        },
      };

    case actions.GET_WHEEL_OF_FORTUNE_CONFIG_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          isFetching: false,
        },
      };

    case actions.GET_WHEEL_OF_FORTUNE_CONFIG_WEB_TOKEN_REQUEST:
      return {
        ...state,
        wofToken: null,
      };

    case actions.GET_WHEEL_OF_FORTUNE_CONFIG_WEB_TOKEN_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
        },
        wofToken: action.payload,
      };

    case actions.GET_WHEEL_OF_FORTUNE_CONFIG_WEB_TOKEN_FAILURE:
      return {
        ...state,
        wofToken: null,
      };

    default:
      return state;
  }
}
