import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
      <path d="M10,11.25 C13.3375,11.25 20,12.925 20,16.25 L20,16.25 L20,20 L17.625,20 L17.625,16.25 C17.625,15.45 13.7125,13.625 10,13.625 C6.2875,13.625 2.375,15.45 2.375,16.25 L2.375,16.25 L2.375,20 L0,20 L0,16.25 C0,12.925 6.6625,11.25 10,11.25 Z M10,0 C12.7625,0 15,2.2375 15,5 C15,7.7625 12.7625,10 10,10 C7.2375,10 5,7.7625 5,5 C5,2.2375 7.2375,0 10,0 Z M10,2.375 C8.55,2.375 7.375,3.55 7.375,5 C7.375,6.45 8.55,7.625 10,7.625 C11.45,7.625 12.625,6.45 12.625,5 C12.625,3.55 11.45,2.375 10,2.375 Z" />
    </svg>
  );
}

export default SvgComponent;
