const path = require('path');

module.exports = {
  i18n: {
    // defaultNS: 'common', // default value
    defaultLocale: 'default',
    locales: ['default', 'de', 'fr'],
    localeDetection: false,
  },
  keySeparator: false,
  localePath: path.resolve('./public/static/locales'),
  saveMissing: process.env.NODE_ENV !== 'production',
  react: {
    useSuspense: false,
  },
};
