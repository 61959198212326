import {
  GET_USER_DATA_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_USER,
} from '@actions/auth';

const initialState = {
  isFetching: false,
  me: {},
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        me: {
          ...state.me,
          email: action.payload.email,
          uid: action.payload.customerId,
        },
      };

    case LOGIN_SUCCESS:
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case LOGOUT_USER:
      return {
        ...state,
        me: {},
      };

    default:
      return state;
  }
}
