export const GET_VOUCHERS_REQUEST = 'GET_VOUCHERS_REQUEST';
export const GET_VOUCHERS_SUCCESS = 'GET_VOUCHERS_SUCCESS';
export const GET_VOUCHERS_FAILURE = 'GET_VOUCHERS_FAILURE';

export const getVouchersRequest = () => ({
  type: GET_VOUCHERS_REQUEST,
});

export const getVouchersSuccess = payload => ({
  payload,
  type: GET_VOUCHERS_SUCCESS,
});

export const getVouchersFailure = payload => ({
  payload,
  type: GET_VOUCHERS_FAILURE,
});

export const GET_VIP_INFO_MODAL_REQUEST = 'GET_VIP_INFO_MODAL_REQUEST';
export const GET_VIP_INFO_MODAL_SUCCESS = 'GET_VIP_INFO_MODAL_SUCCESS';
export const GET_VIP_INFO_MODAL_FAILURE = 'GET_VIP_INFO_MODAL_FAILURE';

export const getVIPInfoModalRequest = () => ({
  type: GET_VIP_INFO_MODAL_REQUEST,
});

export const getVIPInfoModalSuccess = payload => ({
  payload,
  type: GET_VIP_INFO_MODAL_SUCCESS,
});

export const getVIPInfoModalFailure = payload => ({
  payload,
  type: GET_VIP_INFO_MODAL_FAILURE,
});

export const CLEAR_VOUCHERS = 'CLEAR_VOUCHERS';

export const clearVouchers = () => ({
  type: CLEAR_VOUCHERS,
});
