import { breakpoints } from '@themes/main';

export function getFluidSizingFormula(
  minSize: number,
  maxSize: number,
  minBreakpoint = breakpoints[0],
  maxBreakpoint = breakpoints[18],
  unit = 'rem',
  includeEnvironment = '',
  negative = false,
) {
  // eslint-disable-next-line max-len
  return `calc(${
    includeEnvironment
      ? `${negative ? '-1 * ' : ''}env(${includeEnvironment}) + `
      : ''
  }${minSize}${unit} + ${maxSize - minSize} * ((100vw - ${maxBreakpoint}${unit}) / ${
    minBreakpoint - maxBreakpoint
  }));`;
}
