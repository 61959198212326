import { HYDRATE } from 'next-redux-wrapper';

import {
  GET_MOBILE_PLAN_REQUEST,
  GET_MOBILE_PLAN_SUCCESS,
  GET_MOBILE_PLAN_FAILURE,
} from '../actions/mobilePlan';

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export default function mobilePlanReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        data: action.payload.mobilePlan.data,
        error: {},
        isLoading: false,
      };

    case GET_MOBILE_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: {},
      };

    case GET_MOBILE_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        error: {},
      };

    case GET_MOBILE_PLAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: { ...action.payload.error },
      };

    default:
      return state;
  }
}
