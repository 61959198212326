import React from 'react';

export const VIPUnderlayIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 165 84"
    {...props}
  >
    <path d="M157.64032,7.31605664 C152.778606,2.44616618 146.007999,0.00748063051 137.328498,0 L55.1193059,0 L36.1049349,64.7336361 L16.9339751,0 L0,0 L27.4813585,83.9887791 L44.6166621,83.9887791 L66.7963666,15.9786268 L75.576532,15.9786268 L75.576532,68.0325942 L64.6041215,68.0325942 L64.6041215,84 L118.079243,84 L118.079243,53.9615282 L137.74234,53.9615282 C145.944618,53.9615282 152.536266,51.4629976 157.517286,46.4659364 C162.498305,41.4688752 164.992543,35.0130911 165,27.098584 C164.962717,18.7950842 162.50949,12.2009084 157.64032,7.31605664 Z M102.107172,68.0213732 L91.4703091,68.0213732 L91.4703091,15.9786268 L102.107172,15.9786268 L102.107172,68.0213732 Z M137.205464,39.318194 L118.079243,39.318194 L118.079243,14.8565322 L137.205464,14.8565322 C145.07965,14.8565322 149.016743,18.9297355 149.016743,27.0761421 C149.016743,35.2225488 145.07965,39.3032327 137.205464,39.318194 Z" />
  </svg>
);
