import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { explicitNull, or } from 'airbnb-prop-types';

const UserAgentContext = createContext('');

UserAgentContext.displayName = 'UserAgentContext';

export const UserAgentProvider = ({ children, userAgent }) => (
  <UserAgentContext.Provider
    value={{
      userAgent,
      isMobileApp: /ChicoreeApp/i.test(userAgent),
    }}
  >
    {children}
  </UserAgentContext.Provider>
);

UserAgentProvider.propTypes = {
  children: PropTypes.node.isRequired,
  userAgent: or([PropTypes.string, explicitNull()]).isRequired,
};

export const useUserAgent = () => useContext(UserAgentContext);
