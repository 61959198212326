import styled from 'styled-components';

import { NAVBAR_HEIGHT } from '@common/constants/ui';

export const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints[0]}rem;
  min-height: ${({ minHeight }) =>
    `calc(${minHeight || '100vh'} - ${NAVBAR_HEIGHT}rem)`};
  margin: 0 auto;
  ${({ theme }) =>
    theme.getFluidSpacing(
      'padding-left',
      'widthBased',
      0,
      false,
      'safe-area-inset-left',
    )};
  ${({ theme }) =>
    theme.getFluidSpacing(
      'padding-right',
      'widthBased',
      0,
      false,
      'safe-area-inset-right',
    )};
`;

export const ContentWrapper = styled.main`
  flex: 1 0 auto;
`;
