import PropTypes from 'prop-types';
import React from 'react';

export const SpotifyIcon = ({ alt, accessibilityId, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    aria-labelledby={accessibilityId}
    {...props}
  >
    <title id={accessibilityId}>{alt}</title>
    <path d="M23.93 13.33c-4.85-2.88-12.84-3.14-17.47-1.74a1.4 1.4 0 11-.81-2.7c5.3-1.6 14.14-1.3 19.72 2.02a1.4 1.4 0 11-1.44 2.42m-.16 4.26c-.34.56-1.06.73-1.6.39-4.05-2.48-10.21-3.2-15-1.75a1.17 1.17 0 01-.68-2.24c5.47-1.66 12.26-.86 16.9 2 .55.33.72 1.05.38 1.6m-1.84 4.1a.94.94 0 01-1.29.3c-3.53-2.15-7.97-2.64-13.2-1.44a.94.94 0 11-.42-1.83c5.73-1.3 10.64-.74 14.6 1.68.44.27.58.85.31 1.29M15 0a15 15 0 100 30 15 15 0 000-30" />
  </svg>
);

SpotifyIcon.propTypes = {
  alt: PropTypes.string.isRequired,
  accessibilityId: PropTypes.string.isRequired,
};
