import { setAnalyticsMasterSlug } from '@utils/analytics';

import { HOME, MASTER_SLUG_VIEW_NAME_MAP } from '@common/routes';
import { LANGUAGE_DE } from '@common/constants/languages';

export const setMasterSlug = pageUrl => {
  if (!pageUrl) {
    setAnalyticsMasterSlug('');

    return;
  }

  const url = new URL(pageUrl);
  const path = decodeURI(url.pathname.replace(`/${LANGUAGE_DE}`, ''));

  if (path === HOME) {
    setAnalyticsMasterSlug(MASTER_SLUG_VIEW_NAME_MAP[HOME]);

    return;
  }

  if (path) {
    setAnalyticsMasterSlug(path.match(/^\/(.*?)\/$/)[1]);
  }
};
