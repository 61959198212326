export const GET_CUSTOMER_INFO_REQUEST = 'GET_CUSTOMER_INFO_REQUEST';
export const GET_CUSTOMER_INFO_SUCCESS = 'GET_CUSTOMER_INFO_SUCCESS';
export const GET_CUSTOMER_INFO_FAILURE = 'GET_CUSTOMER_INFO_FAILURE';

export const getCustomerInfoRequest = () => ({
  type: GET_CUSTOMER_INFO_REQUEST,
});

export const getCustomerInfoSuccess = payload => ({
  payload,
  type: GET_CUSTOMER_INFO_SUCCESS,
});

export const getCustomerInfoFailure = payload => ({
  payload,
  type: GET_CUSTOMER_INFO_FAILURE,
});

export const GET_CUSTOMER_BADGE_REQUEST = 'GET_CUSTOMER_BADGE_REQUEST';
export const GET_CUSTOMER_BADGE_SUCCESS = 'GET_CUSTOMER_BADGE_SUCCESS';
export const GET_CUSTOMER_BADGE_FAILURE = 'GET_CUSTOMER_BADGE_FAILURE';

export const getCustomerBadgeRequest = () => ({
  type: GET_CUSTOMER_BADGE_REQUEST,
});

export const getCustomerBadgeSuccess = payload => ({
  payload,
  type: GET_CUSTOMER_BADGE_SUCCESS,
});

export const getCustomerBadgeFailure = payload => ({
  payload,
  type: GET_CUSTOMER_BADGE_FAILURE,
});

export const CLEAR_CUSTOMER_INFO = 'CLEAR_CUSTOMER_INFO';

export const clearCustomerInfo = () => ({
  type: CLEAR_CUSTOMER_INFO,
});
