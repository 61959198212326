export const selectBasketProducts = state => state.basket.products;
export const selectBasketShopEventId = state => state.basket.shopEventId;
export const selectBasketProductsIds = state => state.basket.productsIds;
export const selectBasketHasProducts = state => state.basket.hasProducts;
export const selectBasketCalculations = state => state.basket.calculations;
export const selectBasketOrder = state => state.basket.order;
export const selectBasketPaymentMethods = state => state.basket.paymentMethods;
export const selectBasketIsRecalculationNeeded = state =>
  state.basket.isRecalculationNeeded;
export const selectBasketIsFetching = state => state.basket.isFetching;
export const selectIsAddToBasketToolTipVisible = state =>
  state.basket.isAddToBasketToolTipVisible;
export const selectBasketIsLoading = state =>
  state.basket.isFetching || state.basket.isRecalculationNeeded;

// eslint-disable-next-line no-underscore-dangle
export const selectBasketRehydrated = state =>
  state.basket?._persist?.rehydrated;
