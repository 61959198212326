import PropTypes from 'prop-types';
import React from 'react';

export const FacebookIcon = ({ alt, accessibilityId, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="15"
    height="28"
    viewBox="0 0 15 28"
    aria-labelledby={accessibilityId}
    {...props}
  >
    <title id={accessibilityId}>{alt}</title>
    <path d="M13.7 9.52H9.53V6.8c.3-2.56 4.64-2.08 4.64-2.08V.32c-.16-.04-8.62-2-10.3 4.3l-.01.04c-.27.8-.25 4.4-.25 4.86H0v4.82h3.84v13.13h5.59V14.34h4.27l.48-4.82h-.48z" />
  </svg>
);

FacebookIcon.propTypes = {
  alt: PropTypes.string.isRequired,
  accessibilityId: PropTypes.string.isRequired,
};
