import * as React from 'react';

export function SvgComponent(props) {
  return (
    <svg width={22} height={19} viewBox="0 0 22 19" {...props}>
      <defs>
        <path
          d="M2.8 2l17.7 17.7-1.3 1.3L1.5 3.3 2.8 2zm.8 4.6l2.8 2.9A5 5 0 0013 16l2.2 2.1A11.8 11.8 0 010 11.5c.8-2 2-3.6 3.6-4.9zM11 4c5 0 9.3 3.1 11 7.5-.8 2-2 3.6-3.6 4.9l-2.8-2.9A5 5 0 009 7L6.8 4.8C8.1 4.3 9.5 4 11 4zm-3 7l3.4 3.5H11a3 3 0 01-3-3v-.4zm3-2.5a3 3 0 013 3v.4l-3.4-3.4h.4z"
          id="prefix__a"
        />
      </defs>
      <use
        xlinkHref="#prefix__a"
        transform="translate(0 -2)"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
