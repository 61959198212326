import { HYDRATE } from 'next-redux-wrapper';

import * as actions from '@actions/shopEvent';

const initialState = {
  status: {},
  productDetails: {},
  products: [],
  isFetching: false,
};

export default function shopEventReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case HYDRATE:
      return {
        ...payload.shopEvent,
      };

    case actions.GET_SHOP_EVENT_STATUS_REQUEST:
    case actions.GET_SHOP_EVENT_PRODUCT_DETAILS_REQUEST:
    case actions.GET_SHOP_EVENT_PRODUCTS_REQUEST:
    case actions.GET_SHOP_EVENT_PRODUCT_DESCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actions.GET_SHOP_EVENT_STATUS_FAILURE:
    case actions.GET_SHOP_EVENT_PRODUCT_DETAILS_FAILURE:
    case actions.GET_SHOP_EVENT_PRODUCTS_FAILURE:
    case actions.GET_SHOP_EVENT_PRODUCT_DESCRIPTION_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case actions.GET_SHOP_EVENT_STATUS_SUCCESS:
      return {
        ...state,
        status: {
          ...payload,
        },
        isFetching: false,
      };

    case actions.GET_SHOP_EVENT_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: [...payload.results],
        isFetching: false,
      };

    case actions.GET_SHOP_EVENT_PRODUCT_DETAILS_SUCCESS: {
      const colorVariations = payload.colorVariations.map(colorVariation => ({
        ...colorVariation,
        sizes: colorVariation.sizes.map(size => ({
          ...size,
          sanitizedArticleNr: size.articleNr.replace(/\//g, '-'),
        })),
      }));

      return {
        ...state,
        productDetails: {
          ...payload,
          colorVariations,
        },
        isFetching: false,
      };
    }
    case actions.GET_SHOP_EVENT_PRODUCT_DESCRIPTION_SUCCESS: {
      const { id, description } = payload;

      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          description,
        },
        products: state.products.map(product => {
          if (product.productId === id) {
            return {
              ...product,
              description,
            };
          }

          return product;
        }),
        isFetching: false,
      };
    }

    default:
      return state;
  }
}
