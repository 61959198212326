import getConfig from 'next/config';

const { NODE_ENV } = getConfig()?.publicRuntimeConfig || process.env;

export const isProduction = NODE_ENV === 'production';

export const isBrowser = typeof window !== 'undefined';

// check if ios device

export const isIOS = () => {
  if (isBrowser) {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window?.MSStream;
  }

  return false;
};

export const isChicoreeApp = () => {
  if (isBrowser) {
    return /ChicoreeAppProd|ChicoreeAppStage/.test(navigator.userAgent);
  }

  return false;
};
